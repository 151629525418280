import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Row,
    Col,
    Select,
    Tooltip,
    Button,
    Tag,
    Popconfirm
} from 'antd'
import useSWR, { useSWRConfig } from 'swr'
import { 
    Table,
    MigrationModal,
    DestroyMigrationModal,
    Notification
} from '../../components'
import {
    PERMISSIONS
} from '../../environment'
import {
    PrincipalContainer
} from '../../containers'
import { 
    Connections,
    Migration
} from '../../modules'
import { 
    EditFilled,
    DeleteFilled,
    InfoCircleOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    SyncOutlined,
    CheckOutlined
} from '@ant-design/icons'
import { Locale } from '../../helpers/locale'

const { Link } = Typography

export default function MigrationPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const { data: migrationData, isLoading: migrationsIsloading } = useSWR('@migrations', Migration.getClientMigrations)
    const { data: connectionsData } = cache.get('@connections') ?? { data: [] }

    const statusTranslate = {
        1: Locale('pending'),
        2: Locale('executing'),
        3: Locale('error'),
        4: Locale('success')
    }

    const renderTagTable = (status) => {
        let tag = <></>

        switch (status) {
            case 1:
                tag = <Tag icon={<ClockCircleOutlined />} color="default">
                    {statusTranslate[status]}
                </Tag>
                break;
            case 2:
                tag = <Tag icon={<SyncOutlined spin />} color="processing">
                    {statusTranslate[status]}
                </Tag>
                break;
            case 3:
                tag = <Tag icon={<CloseCircleOutlined />} color="error">
                    {statusTranslate[status]}
                </Tag>
                break;
            case 4:
                tag = <Tag icon={<CheckCircleOutlined />} color="success">
                    {statusTranslate[status]}
                </Tag>
                break;
        }

        return tag
    }

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    [filterDB, setFilterDB] = useState(null),
    [dbsToFilter, setDbsToFilter] = useState([]),
    tableColumns = [
        {
            title: Locale('name'),
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ]
        },
        {
            title: Locale('connection'),
            key: 'connection',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => {
                const connection = dbsToFilter.find(conn => conn.value == row.connection_id)
                
                return (
                    <>
                        {connection?.label?.toUpperCase?.() || '-'}
                    </>
                )
            },
        },
        {
            title: 'DEV Status',
            key: 'environment',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {renderTagTable(row.environment.dev.status)}
                </>
            ),
        },
        {
            title: 'SDB Status',
            key: 'environment',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {renderTagTable(row.environment.sdb.status)}
                </>
            ),
        },
        {
            title: 'HML Status',
            key: 'environment',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {renderTagTable(row.environment.hml.status)}
                </>
            ),
        },
        {
            title: 'PRD Status',
            key: 'environment',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {renderTagTable(row.environment.prd.status)}
                </>
            ),
        },
        {
            title: Locale('actions'),
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                return (
                    <Space size="middle">
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                            <Link onClick={() => handleEditModal(row)} href='#'>
                                <EditFilled  style={{ fontSize: '16px' }} />
                            </Link>
                        )}
                        {(userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && Number(row?.status) == 1) && (
                            <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                                <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                            </Link>
                        )}
                        {(userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && Number(row?.status) != 1) && (
                            <Popconfirm
                                // open={openPopup}
                                title={Locale('finalize migration')}
                                description={Locale('are you sure you want to finalize migration?')}
                                onConfirm={async () => await handleFinalize(row._id)}
                                // onCancel={() => setOpenPopup(false)}
                                okText={Locale('yes')}
                                cancelText={Locale('no')}
                            >
                                <CheckOutlined style={{ fontSize: '16px', color: 'green' }} />
                            </Popconfirm>
                        )}
                    </Space>
                )
            },
        },
    ]

    const handleFinalize = async (migrationId) => {
        await Migration.updateClientMigration({ migration: { status: 4 }, id: migrationId })

        Notification({ 
            description: Locale('migration finalized success'),
            title: 'Migrations'
        })

        await mutating()
    }

    const mutating = async () => {
        await mutate('@connection', Connections.getClientConnections({ conn: filterDB }))
        await mutate('@migrations', Migration.getClientMigrations({}))

        setDbsToFilter(connectionsData?.map((db, i) => {
            return {
                label: db.name,
                value: db._id
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleEditModal = (migration) => {
        setEditData({ ...migration })
    }

    const handleDestroyModal = (migration) => {
        setDestroyData({ ...migration })
    }

    const handleCreateModal = () => {
        setCreateData({ close: Math.random() })
    }

    const handleEditModalOpen = () => {
        return <MigrationModal 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            migrationData={editData} 
            migrationId={editData?._id}
            mutating={mutating}
        />
    }

    const handleCreateModalOpen = () => {
        return <MigrationModal 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            migrationData={{}} 
            migrationId={null}
            mutating={mutating}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyMigrationModal
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    const filterByDB = (connId) => {
        setFilterDB(connId)
        setIsLoading(true)
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        setTableData(migrationData?.data?.map((migration, i) => {
            return {
                key: String(i),
                ...migration
            }
        }) || [])

        setDbsToFilter(connectionsData?.map((db, i) => {
            return {
                label: db.name,
                value: db._id
            }
        }) || [])

        setIsLoading(false)
    }, [migrationData, migrationsIsloading])

    useEffect(() => {
        if(filterDB?.length) mutating()
    }, [filterDB])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='12' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Migrations
                        <Tooltip placement="topLeft" title={Locale('the migration allow you to run scripts in the db, like update tables, create tables...')}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                        {/* <Select
                            defaultValue={''}
                            style={{
                                width: 150,
                                marginLeft: 15,
                                verticalAlign: 7
                            }}
                            onSelect={(e) => filterByDB(e)}
                            options={[
                                {
                                    label: 'Filtrar por DB',
                                    value: ''
                                },
                                ...dbsToFilter
                            ]}
                        /> */}
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col style={{ overflow: 'scroll' }} span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}