import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Row,
    Col,
    Select,
    Tag
} from 'antd'
import { useSWRConfig } from 'swr'
import { 
    Table,
    LogsModal,
} from '../../components'
import {
    PERMISSIONS
} from '../../environment'
import {
    PrincipalContainer
} from '../../containers'
import { Workers, PullRequest, Apps } from '../../modules'
import { 
    SearchOutlined
} from '@ant-design/icons'
import { Locale } from '../../helpers/locale'

const { Link } = Typography

export default function LogsPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const { data: apisData } = cache.get('@apps') ?? { data: [] }
    const { data: workersData, isLoading: workersIsLoading } = cache.get('@workers') ?? { data: [] }
    const { data: groupsData, isLoading: groupsIsLoading } = cache.get('@groups') ?? { data: [] }

    const [isMobile, setIsMobile] = useState(false),
        [tableData, setTableData] = useState([]),
        [isLoading, setIsLoading] = useState(true),
        [logsData, setLogsData] = useState({}),
        [openLogsModal, setOpenLogsModal] = useState(false),
        [filterGroup, setFilterGroup] = useState(null),
        [groupsToFilter, setGroupsToFilter] = useState([])

    const renderTagTable = (status) => {
        let tag = <></>

        switch (status) {
            case 0:
                tag = <Tag color="error">
                    {`offline`}
                </Tag>
                break
            case 1:
                tag = <Tag color="success">
                    {`online`}
                </Tag>
                break
            case 2:
                tag = <Tag color="default">
                    {`Desativada[offline]`}
                </Tag>
                break
        }

        return tag
    }

    const tableColumns = [
        {
            title: Locale('name'),
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: Locale('type'),
            dataIndex: 'app_type',
            key: 'app_type',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                renderTagTable(row.status)
            )
        },
        {
            title: Locale('actions'),
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                const api = tableData.filter(api => api?._id === row?._id)?.[0] || {}

                return (
                    <Space size="middle">
                        <Link onClick={() => handleOpenLogsModal(api)} href='#'>
                            <SearchOutlined  style={{ fontSize: '16px' }} />
                        </Link>
                    </Space>
                )
            },
        },
    ]
    
    const handleOpenLogsModal = (app) => {
        setLogsData({ ...app })
    }

    const mutating = async () => {
        await mutate('@workers', Workers.getClientWorkers({ group: filterGroup }))
        await mutate('@apps', Apps.getClientApps({ group: filterGroup }))
        await mutate('@pull_requests', PullRequest.getPullRequests())

        const newCacheWorkers = cache.get('@workers')?.data ?? []
        const workersDataTable = newCacheWorkers?.map((worker, i) => {
            return {
                key: String(i),
                ...worker,
                app_type: 'worker'
            }
        }) || []

        const newCacheApis = cache.get('@apps')?.data ?? []
        const apisDataTable = newCacheApis?.map((api, i) => {
            return {
                key: String(i),
                ...api,
                app_type: 'api'
            }
        }) || []

        setTableData([ ...apisDataTable, ...workersDataTable ])

        setLogsData({})

        setIsLoading(false)
    }

    const handleLogsModal = () => {
        return <LogsModal 
            title={`#${logsData?._id} - ${logsData?.name} logs`} 
            open={openLogsModal} 
            handleOpen={setOpenLogsModal} 
            logsData={logsData} 
            isMobile={isMobile}
        />
    }

    const filterByGroup = (groupId) => {
        setFilterGroup(groupId)
        setIsLoading(true)
    }

    useEffect(() => {
        if(Object.keys(logsData).length) setOpenLogsModal(true)
        else setOpenLogsModal(false)
    }, [logsData])

    useEffect(() => {
        const workersTable = workersData?.map((worker, i) => {
            return {
                key: String(i),
                ...worker,
                app_type: 'worker'
            }
        }),
        apisTable = apisData?.map((api, i) => {
            return {
                key: String(i),
                ...api,
                app_type: 'api'
            }
        })

        setTableData([ ...workersTable, ...apisTable ] || [])
    }, [isLoading, workersIsLoading])
    
    useEffect(() => {
        setGroupsToFilter(groupsData?.map((group, i) => {
            return {
                label: group.name,
                value: group._id
            }
        }) || [])

        setIsLoading(false)
    }, [groupsIsLoading])

    useEffect(() => {
        mutating()
    }, [filterGroup])

    window.addEventListener('mobileResize', () => {
        setIsMobile(true)
    })

    window.addEventListener('desktopResize', () => {
        setIsMobile(false)
    })

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_LIST) && (
                    handleLogsModal()
                )
            }
            <PrincipalContainer menuItemSelected='19' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Logs
                        <Select
                            defaultValue={''}
                            style={{
                                width: 150,
                                marginLeft: 15
                            }}
                            onSelect={(e) => filterByGroup(e)}
                            options={[
                                {
                                    label: Locale('filter by group'),
                                    value: ''
                                },
                                ...groupsToFilter
                            ]}
                        />
                    </Typography.Title>
                    <Row>
                        <Col style={{ overflow: 'scroll' }} span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}