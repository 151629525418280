import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Select,
    Typography
} from 'antd'
import { Notification } from "../.."
import { Tasks } from "../../../modules"
import { Locale } from '../../../helpers/locale'
import { useSWRConfig } from 'swr'

const { Item } = Form,
    { Text, Link } = Typography

export default function TasksModal ({ title = '', open = false, handleOpen = () => {}, editTask = {}, mutating = () => {} }) {
    const { cache } = useSWRConfig()
    
    const { data: workersData, isLoading: workersIsLoading } = cache.get('@workers') ?? { data: [] }

    const [task, setTask] = useState({
        'name': null,
        'crontab_expression': null,
        'description': '',
        'worker_id': null
    }),
    [loading, setLoading] = useState(false),
    isEditModal = editTask?._id

    const handleSave = async () => {
        setLoading(true)

        if(!task?.name?.length) {
            setLoading(false)

            return Notification({ 
                description: Locale('the name field is required'),
                title: Locale('save'),
                type: 'warning'
            })
        }

        if(!task?.crontab_expression?.length) {
            setLoading(false)

            return Notification({ 
                description: Locale('the expression field is required'),
                title: Locale('save'),
                type: 'warning'
            })
        }

        console.log(task)

        if(!task?.worker_id) {
            setLoading(false)

            return Notification({ 
                description: Locale('the worker is required'),
                title: Locale('save'),
                type: 'warning'
            })
        }

        if(isEditModal) {
            await Tasks.updateClientTask({ task, id: task?._id })
        } else {
            await Tasks.createClientTask({ task })
        }
        
        setLoading(false)
        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? Locale('edited') : Locale('created')
        
        Notification({ 
            description: `Task ${modalType} ${Locale('with success')}`,
            title: 'Task'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = task[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            if(type && !isDelete) {
                if(!value) {
                    task[key] = []
                } else {
                    if(!Array.isArray(task[key])) task[key] = []
                    task[key].push(value)
                }
            } else {
                task[key] = value
            }
        }

        setTask({ ...task })
    }

    useEffect(() => {
        if(editTask?._id) {
            setTask({ ...editTask })
        } else {
            setTask({
                'name': null,
                'crontab_expression': null,
                'worker_id': null,
                'description': ''
            })
        }
    }, [editTask, open])

    useEffect(() => {
        setLoading(workersIsLoading)
    }, [workersIsLoading])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => await handleSave()}
            okText={Locale('save')}
            cancelText={Locale('cancel')}
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            <Form 
                layout={'vertical'}
                initialValues={{
                    ...task
                }}
            >
                <Item label={Locale('name')}>
                    <Input placeholder={Locale('name of task')} onChange={(e) => handleValue(e, 'name')} value={task?.name} />
                </Item>
                <Item label={Locale('description')}>
                    <Input.TextArea onChange={(e) => handleValue(e, 'description')} value={task?.description} />
                </Item>
                <Item label={Locale('expression')}>
                    <Input placeholder={'* * * * *'} onChange={(e) => handleValue(e, 'crontab_expression')} value={task?.crontab_expression} />
                    <Text>
                        <Link href="https://crontab.cronhub.io" target="_blank">
                            {Locale('this generator will help you, click here')}
                        </Link>
                    </Text>
                </Item>
                <Item label={Locale('worker')}>
                    <Select
                        style={{
                            width: '100%',
                        }}
                        placeholder={Locale('select worker')}
                        loading={workersData?.length > 0 ? false : true}
                        options={workersData?.length > 0 && [ { _id: null, name: Locale('none') }, ...workersData ]?.map(worker => {
                            return {
                                label: worker.name,
                                value: worker._id
                            }
                        }) || []} 
                        onChange={(e) => handleValue({ target: { value: e } }, 'worker_id')}
                        onInputKeyDown={(e) => e.preventDefault()}
                        value={task?.worker_id}
                    />
                </Item>
            </Form>
        </Modal>
    )
} 