import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientTasks = async ({ limit = 500, offset = 0, group = null }) => {
    if(!verifyPermission('tasks', permissionsStatus.list)) return []

    const response  = await API.get(URLS.tasks.list(limit, offset, group))

    return response?.data
}

const createClientTask = async ({ task }) => {
    if(!verifyPermission('tasks', permissionsStatus.create)) return {}

    const { data: response, isError } = await API.post(URLS.tasks.create, task)

    return response?.data || isError
}

const updateClientTask = async ({ task, id }) => {
    if(!verifyPermission('tasks', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.tasks.update(id), task)

    return response?.data || isError
}

const deleteClientTask = async ({ id }) => {
    if(!verifyPermission('tasks', permissionsStatus.delete)) return {}

    const { data: response, isError } = await API.delete(URLS.tasks.delete(id))

    return response?.data || isError
}

const App = {
    getClientTasks,
    createClientTask,
    updateClientTask,
    deleteClientTask
}

export default App