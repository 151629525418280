import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form
} from 'antd'
import { Locale } from '../../../helpers/locale'

const { Item } = Form

export default function NewKeyModal ({ open = false, handleOpen = () => {}, keys = {}, setNewMetaKey = () => {} }) {
    const [newKey, setNewKey] = useState({
        description: '',
        id: ''
    })

    const handleSave = () => {
        if(!keys?.meta_keys) {
            keys.meta_keys = {}
        }

        const envs = [
            'prd',
            'sdb',
            'hml',
            'dev'
        ]

        envs.forEach(env => {
            if(!keys.meta_keys?.[newKey.id]) {
                keys.meta_keys[newKey.id] = {
                    title: newKey.id,
                    description: newKey.description,
                }
            } 
            
            keys.meta_keys[newKey.id][env] = ''
        })

        setNewMetaKey({ ...keys })

        handleOpen(false)
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = newKey[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            if(type && !isDelete) {
                if(!value) {
                    newKey[key] = []
                } else {
                    if(!Array.isArray(newKey[key])) newKey[key] = []
                    newKey[key].push(value)
                }
            } else {
                newKey[key] = value
            }
        }

        setNewKey({ ...newKey })
    }

    useEffect(() => {
        setNewKey({
            title: '',
            description: '',
            id: ''
        })
    }, [open])

    return (
        <Modal
            title={Locale('new key')}
            centered
            open={open}
            onCancel={async () => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => handleSave()}
            okText={Locale('add')}
            cancelText={Locale('cancel')}
            cancelButtonProps={{ type: 'text' }}
        >
            <Form 
                layout={'vertical'}
                initialValues={{
                    ...newKey
                }}
            >
                <Item label={Locale('id')}>
                    <Input placeholder={Locale('identification')} onChange={(e) => handleValue(e, 'id')} value={newKey?.id} />
                </Item>
                <Item label={Locale('description')}>
                    <Input onChange={(e) => handleValue(e, 'description')} value={newKey?.description} />
                </Item>
            </Form>
        </Modal>
    )
} 