import React, { useState, useEffect } from "react"
import { 
    Tag,
    Button,
    Switch,
    Select,
    Input,
    Form, 
    Tabs,
    Collapse,
    Typography
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    ThunderboltOutlined,
    CoffeeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { Locale } from "../../../helpers/locale"

const { Item } = Form

export default function TabList ({ values = {}, isEdit = true, appId = null, endpointName = null, endpointName2 = null }) {
    const [requestParams, setRequestParams] = useState(values?.request),
        [responseParams, setResponseParams] = useState(values?.response?.schema),
        [endpointDisabled, setEndpointDisabled] = useState(false),
        [rawQuery, setRawQuery] = useState(false),
        [pagination, setPagination] = useState(values?.response?.has_total_count),
        [responsePreview, setResponsePreview] = useState(false),
        [responseParamInputValue, setResponseParamInputValue] = useState(''),
        [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
        [editorCodePrd, setEditorCodePrd] = useState(''),
        [editorCodeHml, setEditorCodeHml] = useState(''),
        [editorCodeSdb, setEditorCodeSdb] = useState(''),
        [editorCodeDev, setEditorCodeDev] = useState(''),
        [mergeValues, setMergeValues] = useState([])

    const orderByTypes = [
        'ASC',
        'DESC'
    ]

    useEffect(() => {
        if(values?.request?.raw_query?.length) {
            setRawQuery(true)
        } else {
            setRawQuery(false)
        }

        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [appId])

    useEffect(() => {
        setResponseParams(values?.response?.schema)
        setRequestParams(values?.request)

        if(values?.request?.merge_values?.length > 0) {
            const mergeValue0Split = values?.request?.merge_values?.[0].split('|') || [],
                mergeValues1Split = values?.request?.merge_values?.[1].split('|') || []

            let mergeValues = []

            if(mergeValue0Split?.length) {
                mergeValues.push([mergeValue0Split[0], mergeValue0Split[1], mergeValue0Split[2], mergeValue0Split[3]])
            }

            if(mergeValues1Split?.length) {
                mergeValues.push([mergeValues1Split[0], mergeValues1Split[1], mergeValues1Split[2], mergeValues1Split[3]])
            }
            
            setMergeValues([ ...mergeValues ])
        } else {
            setMergeValues([
                ['data'],
                []
            ])
        }  
    }, [values, appId])

    useEffect(() => {
        if((values?.request?.dev_mode?.prd.code[0])) {
            const codePrd = values?.request?.dev_mode?.prd.code
            if((editorCodePrd.length != codePrd.length)) setEditorCodePrd(codePrd)
        }

        if((values?.request?.dev_mode?.hml.code[0])) {
            const codeHml = values?.request?.dev_mode?.hml.code
            if((editorCodeHml.length != codeHml.length)) setEditorCodeHml(codeHml)
        }

        if((values?.request?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.request?.dev_mode?.sdb.code
            if((editorCodeSdb.length != codeSdb.length)) setEditorCodeSdb(codeSdb)
        }

        if(values?.request?.dev_mode?.dev.code[0]) {
            const codeDev = values?.request?.dev_mode?.dev.code
            if((editorCodeDev.length != codeDev.length)) setEditorCodeDev(codeDev)
        } 
    }, [requestParams])

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('production')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="75vh" 
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('homolog')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="75vh" 
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="75vh" 
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('dev')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="75vh" 
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>
    ]

    const tabs = [
        <>
            <Collapse
                accordion={true}
                bordered={false}
            >
                <Collapse.Panel header={
                    <>
                        <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                            {Locale('request')}
                        </Typography.Title>
                    </>
                }>
                    <Item label={(<>
                        {'RAW Query'}
                            <Switch 
                                onChange={(e) => e.preventDefault()}
                                style={{ float: 'right', marginLeft: 10 }}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={rawQuery}
                                disabled={true}
                            />
                        </>)}
                    >
                        {
                            rawQuery && (
                                <>
                                    <Input.TextArea 
                                        placeholder={'SELECT * FROM {table} WHERE id = {id} AND user_id = {user_id} LIMIT {limit} OFFSET {offset}'}
                                        value={requestParams?.raw_query?.[0]}
                                        onChange={(e) => e.preventDefault()}
                                        disabled={true}
                                    />
                                </>
                            )
                        }
                    </Item>
                    {
                        !rawQuery && (
                            <>
                                <Item label={(<>
                                    {'Order by'}
                                    </>)}
                                >
                                    <Input 
                                        placeholder={Locale('the default value is id')}
                                        value={requestParams?.order_by}
                                        onChange={(e) => e.preventDefault()}
                                        disabled={true}
                                    />
                                </Item>
                                <Item label={(<>
                                    {Locale('type of order by')}
                                    </>)}
                                >
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        disabled={true}
                                        options={orderByTypes?.map(type => {
                                            return {
                                                label: type,
                                                value: type.toUpperCase()
                                            } 
                                        })} 
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={values?.request?.order_by_type}
                                        defaultValue={isEdit && requestParams?.order_by_type || 'DESC'}
                                    />
                                </Item>
                            </>
                        )
                    }
                </Collapse.Panel>
                <Collapse.Panel header={
                    <>
                        <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                            {Locale('response')}
                        </Typography.Title>
                    </>
                }>
                    <div style={{ marginBottom: 10 }}>
                        {Locale('pagination')}
                        <Switch 
                            onChange={(e) => e.preventDefault()}
                            style={{ marginLeft: 10 }}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={pagination}
                            disabled={true}
                        />
                    </div>
                    <Select
                        mode="tags"
                        style={{
                            width: '83%',
                        }}
                        allowClear
                        bordered={false}
                        showArrow={false}
                        disabled={true}
                        options={responseParams?.map(param => {
                            return {
                                label: param,
                                value: param
                            }
                        })} 
                        onClear={(e) => e.preventDefault()}
                        onChange={(e) => e.preventDefault()}
                        dropdownStyle={{ display: 'none' }} 
                        onInputKeyDown={(e) => e.preventDefault()}
                        value={responseParams}
                    />
                </Collapse.Panel>
            </Collapse>
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Dev Mode
            </h3>
            <Tabs
                defaultActiveKey="2"
                items={[Locale('production'), Locale('homolog'), 'Sandbox', Locale('dev')].map((tabName, i) => {
                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabsEnv[i],
                    }
                })}
            />
        </>
    ]

    return (
        <div>
            <h3 style={{ textAlign: 'left' }}>
                <Tag color="success">LIST | GET</Tag> {isEdit ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/${endpointName2}/{limit}/{offset}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{limit}/{offset}`) : `/v1/{app_id}|{endpoint_name}/?{endpoint_name2}/{limit}/{offset}`}
                <br></br>{Locale('or')}<br></br>
                {isEdit && endpointName2?.length > 0 ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}/${endpointName2}/{limit}/{offset}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{limit}/{offset}`) : `/v1/{app_id}|{endpoint_name}/{id}/?{endpoint_name2}/{limit}/{offset}`}
                    <Switch 
                        disabled={true}
                        onChange={(e) => e.preventDefault()}
                        style={{ float: 'right' }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={endpointDisabled}
                    />
            </h3>

            <Tabs
                defaultActiveKey="2"
                items={[ThunderboltOutlined, CoffeeOutlined].map((Icon, i) => {
                    const tabName = i == 0 ? 'Simple Mode' : 'Dev Mode' 
                    return {
                        label: (
                        <span>
                            <Icon />
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabs[i],
                    }
                })}
            />
            
        </div>
    )
} 