import React, { useEffect, useState } from "react"
import { 
    Modal,
    Col,
    Row,
    Button,
    Typography,
    Select, 
    Tabs,
    Tag, 
    Switch,
    Input,
    Form,
    Collapse,
    Skeleton
} from 'antd'
import DevMode from "../worker-modal/dev-mode"
import { useSWRConfig } from "swr"
import { Locale } from "../../../helpers/locale"
import { 
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons'
import TabList from "./tab-list"
import TabGet from "./tab-get"
import TabPost from "./tab-post"
import TabPut from "./tab-put"
import TabDelete from "./tab-delete"
import { Rollback } from "../../../modules"
import { Notification } from "../.."

const { Item } = Form,
    { Panel } = Collapse

export default function RollbackModal ({ title = '', currentAppVersion = null, isAPI = false, open = false, handleOpen = () => {}, rollbackData = [], mutating = () => {} }) {
    const { cache } = useSWRConfig()

    const connectionsData = cache.get('@connections')
    const groupsData = cache.get('@groups')
    const aclData = cache.get('@acls')
    const workersData = cache.get('@workers')
    const corsData = cache.get('@cors')
    
    const [collapseItems, setCollapseItems] = useState([]),
        [isLoading, setIsLoading] = useState(true)

    const handleVersion = async (rollbackID) => {
        await Rollback.updateClientRollback({ rollbackData: { app_type: isAPI ? 0 : 1 }, id: rollbackID })
    
        await mutating()

        Notification({
            description: Locale('rollbacked with success'),
            title: Locale('rollback'),
            type: 'success'
        })

        handleOpen(false)
    }

    const modalBodyWorker = (rollback) => {
        const workerData = rollback.app_data,
            tabs = [
                {
                    name: <Tag color="success">LIST | GET</Tag>,
                    content: <TabList values={{
                            response: workerData?.response?.list,
                            request: workerData?.request?.list
                        }} 
                        appId={workerData?._id}
                        endpointName={workerData?.endpoint_name}
                        endpointName2={workerData?.endpoint_name2}
                    />
                },
                {
                    name: <Tag color="success">GET</Tag>,
                    content: <TabGet values={{
                            response: workerData?.response?.show,
                            request: workerData?.request?.show
                        }} 
                        appId={workerData?._id}
                        endpointName={workerData?.endpoint_name}
                        endpointName2={workerData?.endpoint_name2}
                    />
                },
                {
                    name: <Tag color="processing">POST</Tag>,
                    content: <TabPost values={{
                            request: workerData?.request?.create
                        }} 
                        appId={workerData?._id}
                        endpointName={workerData?.endpoint_name}
                        endpointName2={workerData?.endpoint_name2}
                        workers={workersData}
                    />
                },
                {
                    name: <Tag color="processing">PUT</Tag>,
                    content: <TabPut values={{
                            request: workerData?.request?.update
                        }} 
                        appId={workerData?._id}
                        endpointName={workerData?.endpoint_name}
                        endpointName2={workerData?.endpoint_name2}
                        workers={workersData}
                    />
                },
                {
                    name: <Tag color="error">DELETE</Tag>,
                    content: <TabDelete values={{
                            response: workerData?.response?.delete,
                            request: workerData?.request?.delete
                        }} 
                        appId={workerData?._id}
                        endpointName={workerData?.endpoint_name}
                        endpointName2={workerData?.endpoint_name2}
                        workers={workersData}
                    />
                }
            ]

        console.log(workerData)

        return (
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>

                    <Form 
                        layout={'vertical'}
                        initialValues={{
                            ...workerData
                        }}
                    >
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={9}>
                                <Item label={Locale('name')}>
                                    <Input disabled={true} placeholder={Locale('worker name')} onChange={(e) => e.preventDefault()} value={workerData?.name}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={9}>
                                <Item label={Locale('group')}>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        options={[{ label: Locale('none'), value: null }].concat(groupsData?.data?.map(group => {
                                            return {
                                                label: group.name,
                                                value: group._id
                                            }
                                        })) || []} 
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={workerData?.group_id}
                                        disabled={true}
                                    />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('show errors')}>
                                    <Switch 
                                        onChange={(e) => e.preventDefault()}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={workerData?.errors_log}
                                        disabled={true}
                                    />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={18}>
                                <Item label={Locale('description')}>
                                    <Input.TextArea disabled={true} placeholder={Locale('worker description')} onChange={(e) => e.preventDefault()} value={workerData?.worker_description}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('connection')}>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder={Locale('select connection')}
                                        loading={connectionsData?.data?.length > 0 ? false : true}
                                        options={connectionsData?.data?.length > 0 && connectionsData?.data?.map(conn => {
                                            return {
                                                label: conn.name,
                                                value: conn._id
                                            }
                                        }) || []} 
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={workerData?.connection_id}
                                        disabled={true}
                                    />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={6}>              
                                <Item label="Status">
                                    <Switch 
                                        onChange={(e) => e.preventDefault()}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={workerData?.status}
                                        disabled={true}
                                    />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col span={24}>
                                <DevMode
                                    handleValue={(e) => e.preventDefault()}
                                    values={workerData}
                                    isEdit={true}
                                    workerId={workerData?.id || null}
                                    readOnly={true}
                                />
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col span={24}>
                                <Item label={Locale('execute after')}>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder={Locale('select worker')}
                                        loading={workersData?.data?.length > 0 ? false : true}
                                        options={workersData?.data?.length > 0 && workersData?.data?.filter(worker => worker?._id != workerData?._id)?.map(worker => {
                                            return {
                                                label: worker.name,
                                                value: worker._id
                                            }
                                        }) || []} 
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={workerData?.worker_id}
                                        disabled={true}
                                    />
                                </Item>
                            </Col>
                        </Row>
                        <Button style={{ float: 'right', marginTop: 10 }} type="primary" onClick={() => handleVersion(rollback._id)}>{Locale('use this version')}</Button>
                    </Form>

                </Col>
            </Row>
        )
    }
    console.log(workersData, workersData?.data?.filter(worker => worker?._id))
    const modalBodyAPI = (rollback) => {
        const appData = rollback.app_data,
            tabs = [
                {
                    name: <Tag color="success">LIST | GET</Tag>,
                    content: <TabList values={{
                            response: appData?.response?.list,
                            request: appData?.request?.list
                        }} 
                        appId={appData?._id}
                        endpointName={appData?.endpoint_name}
                        endpointName2={appData?.endpoint_name2}
                    />
                },
                {
                    name: <Tag color="success">GET</Tag>,
                    content: <TabGet values={{
                            response: appData?.response?.show,
                            request: appData?.request?.show
                        }} 
                        appId={appData?._id}
                        endpointName={appData?.endpoint_name}
                        endpointName2={appData?.endpoint_name2}
                    />
                },
                {
                    name: <Tag color="processing">POST</Tag>,
                    content: <TabPost values={{
                            request: appData?.request?.create
                        }} 
                        appId={appData?._id}
                        endpointName={appData?.endpoint_name}
                        endpointName2={appData?.endpoint_name2}
                        workers={workersData}
                    />
                },
                {
                    name: <Tag color="processing">PUT</Tag>,
                    content: <TabPut values={{
                            request: appData?.request?.update
                        }} 
                        appId={appData?._id}
                        endpointName={appData?.endpoint_name}
                        endpointName2={appData?.endpoint_name2}
                        workers={workersData}
                    />
                },
                {
                    name: <Tag color="error">DELETE</Tag>,
                    content: <TabDelete values={{
                            response: appData?.response?.delete,
                            request: appData?.request?.delete
                        }} 
                        appId={appData?._id}
                        endpointName={appData?.endpoint_name}
                        endpointName2={appData?.endpoint_name2}
                        workers={workersData}
                    />
                }
            ]

        return (
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <Form layout="vertical">
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={9}>
                                <Item label={Locale('name')}>
                                    <Input placeholder={Locale('API name')} 
                                    disabled={true}
                                    onChange={(e) => e.preventDefault()} value={appData?.name}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={9}>
                                <Item label={Locale('table')}>
                                    <Input placeholder={Locale('table name')} 
                                    disabled={true}
                                    onChange={(e) => e.preventDefault()} value={appData?.table}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('group')}>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        options={[{ label: Locale('none'), value: null }].concat(groupsData?.data?.map(group => {
                                            return {
                                                label: group.name,
                                                value: group._id
                                            }
                                        })) || []} 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={appData?.group_id}
                                    />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={18}>
                                <Item label={Locale('description')}>
                                    <Input.TextArea placeholder={Locale('API description')} 
                                    disabled={true}
                                    onChange={(e) => e.preventDefault()} 
                                    value={appData?.app_description}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label="CORS">
                                    <Select
                                        mode="multiple"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder={Locale('select CORS')}
                                        options={corsData?.data?.length > 0 && corsData?.data?.map(cors => {
                                            return {
                                                label: cors.name,
                                                value: cors._id
                                            }
                                        }) || []} 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={appData?.cors_ids}
                                    />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('connection')}>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder={`${Locale('select')} ${Locale('connection')}`}
                                        options={connectionsData?.data?.length > 0 && connectionsData?.data?.map(conn => {
                                            return {
                                                label: conn.name,
                                                value: conn._id
                                            }
                                        }) || []} 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={appData?.connection_id}
                                    />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label="ACL">
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder={Locale('select ACL')}
                                        options={[{ label: Locale('none'), value: null }].concat(aclData?.data?.map(acl => {
                                            return {
                                                label: acl.name,
                                                value: acl._id
                                            }
                                        })) || []} 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        onInputKeyDown={(e) => e.preventDefault()}
                                        value={appData?.acl_id}
                                    />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('endpoint name')}>
                                    <Input placeholder={Locale('endpoint name')} 
                                    disabled={true}
                                    onChange={(e) => e.preventDefault()} 
                                    value={appData?.endpoint_name}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('endpoint name') + ' 2'}>
                                    <Input placeholder={Locale('endpoint name') + ' 2'} 
                                    disabled={true}
                                    onChange={(e) => e.preventDefault()} 
                                    value={appData?.endpoint_name2}  />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('cache queries')}>
                                    <Switch 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={appData?.cache_query}
                                    />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('authentication')}>
                                    <Switch 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={appData?.has_authentication}
                                    />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label="Status">
                                    <Switch 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={appData?.status == 1 ? true : false}
                                    />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Item label="Query String">
                                    <Switch 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={appData?.query_string_sql}
                                    />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={6}>
                                <Item label={Locale('show errors')}>
                                    <Switch 
                                        disabled={true}
                                        onChange={(e) => e.preventDefault()}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={appData?.debug_on_response}
                                    />
                                </Item>
                            </Col>
                        </Row>

                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col span={24}>
                                <Tabs
                                    tabPosition={'top'}
                                    items={tabs.map((tab, i) => {
                                        const id = String(i);
                                        return {
                                            label: tab.name,
                                            key: id,
                                            children: tab.content
                                        }
                                    })}
                                />
                            </Col>
                        </Row>
                        <Button style={{ float: 'right', marginTop: 10 }} type="primary" onClick={() => handleVersion(rollback._id)}>{Locale('use this version')}</Button>
                    </Form>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if(rollbackData?.length) {
            setCollapseItems(rollbackData?.sort((a, b) => b.version - a.version)?.map((rollback, i) => {
                let tag = ''

                if(rollback._id == currentAppVersion) tag = <Tag color="blue">{Locale('current version')}</Tag>
                
                return {
                    key: rollback._id,
                    label: <Typography.Text>
                        {tag}
                        {`#${rollback._id} - ${Locale('version')} (${rollback.version})`}   
                    </Typography.Text>,
                    children: isAPI ? modalBodyAPI(rollback) : modalBodyWorker(rollback)
                }
            }))
        } else {
            setCollapseItems([])
        }

        setIsLoading(false)
    }, [rollbackData])

    return (
        <Modal
            title={title}
            centered
            open={open}
            width={1000}
            onCancel={() => {
                handleOpen(false)
            }}
            cancelText={Locale('close')}
            okButtonProps={{
                style: {
                    display: "none"
                }
            }}
        >
            {isLoading && <Skeleton loading={isLoading} active /> || ((!isLoading && collapseItems?.length) && (
                <Collapse accordion bordered={false}>
                {
                    collapseItems.map(rollback => {
                        return (
                            <Panel header={rollback.label} key={rollback.key}>
                                {rollback.children}
                            </Panel>
                        )
                    })
                }
                </Collapse>
            ) || <Typography.Title level={5} style={{ textAlign: 'center' }}>{Locale('no data to display')}</Typography.Title>)}
        </Modal>
    )
} 