import React, { useEffect, useState } from "react"
import { 
    Modal,
    Col,
    Row,
    Typography,
    Select, 
    Tag,
    Divider,
    Tooltip,
    Input,
    Form,
    Collapse,
    Skeleton
} from 'antd'
import {
    InfoCircleOutlined
} from '@ant-design/icons'
import { 
    Connections
} from "../../../modules"
import { Editor } from '@monaco-editor/react'
import { Locale } from "../../../helpers/locale"

const { Item } = Form,
    { Panel } = Collapse

export default function MigrationLogsModal ({ open = false, handleOpen = () => {}, connectionId = null, setConnectionId = () => {} }) {
    const [migrations, setMigrations] = useState([]),
        [collapseItems, setCollapseItems] = useState([]),
        [isLoading, setIsLoading] = useState(true)

    const modalBody = (migration) => {
        const tagRender = (props) => {
            const { label, value, closable, onClose } = props
            const onPreventMouseDown = (event) => {
              event.preventDefault()
              event.stopPropagation()
            }

            let color = 'primary'

            switch (value) {
                case 'dev':
                    color = 'green'
                    break
                case 'sdb':
                    color = 'purple'
                    break
                case 'hml':
                    color = 'volcano'
                    break;
                case 'prd':
                    color = 'red'
                    break
            }

            return (
                <Tag
                    color={color}
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                        marginRight: 3
                    }}
                >
                    {label}
                </Tag>
            )
        }

        const options = [
            {
                label: Locale('dev'),
                value: 'dev'
            },
            {
                label: Locale('sdb'),
                value: 'sdb'
            },
            {
                label: Locale('homolog'),
                value: 'hml'
            },
            {
                label: Locale('production'),
                value: 'prd'
            },
        ]

        return (
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <Form layout="vertical">
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={24}>
                                <Item label={Locale('name')}>
                                    <Input placeholder="Nome da migration" disabled={true} onChange={(e) => e.preventDefault()} value={migration?.name}  />
                                </Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Item label={Locale('description')}>
                                    <Input.TextArea placeholder="Descrição da migration" disabled={true} onChange={(e) => e.preventDefault()} value={migration?.migration_description}  />
                                </Item>
                            </Col>
                        </Row>
                    </Form>
                    <Divider orientation="left">
                        {'SQL'}
                        <Tooltip placement="topLeft" title={Locale('executed sql code')}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </Divider>
                    <Editor
                        language="mysql"
                        value={migration.sql}
                        theme='vs-dark'
                        height="50vh" 
                        onChange={(_, e) => {
                            e.preventDefault()
                        }}
                        options={{
                            autoIndent: "full",
                            readOnly: true
                        }}
                    />
                </Col>
                <Col span={24}>
                    <Divider orientation="left">
                        {Locale('environments')}
                        <Tooltip placement="topLeft" title={Locale('environments where executed SQL')}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </Divider>
                    <Select
                        mode="multiple"
                        tagRender={tagRender}
                        value={options.filter(opt => migration?.environment?.[opt.value]?.status != 1)?.length && options.filter(opt => migration?.environment?.[opt.value]?.status != 1) || []}
                        style={{
                            width: '100%',
                        }}
                        disabled={true}
                    />
                    <Divider />
                    <Typography.Title style={{ marginTop: 20 }} level={3}>Logs</Typography.Title>
                    <pre style={{ 
                        padding: 10, 
                        border: '1px solid #ccc',
                        borderRadius: 5
                    }}>
                        {migration?.logs?.map(log => {
                            const success = log.match(/SUCESSO/),
                                error = log.match(/ERRO/)
                            return (
                                <>
                                    <Typography.Paragraph>
                                        {(success || error) && tagRender({
                                            label: log,
                                            value: success && 'dev' || error && 'prd'
                                        }) || log}
                                    </Typography.Paragraph>
                                </>
                            )
                        })}
                    </pre>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if(migrations?.length) {
            setCollapseItems(migrations?.map((mi, i) => {
                return {
                    key: mi._id,
                    label: mi?.migration_description?.length && mi?.migration_description || `#${mi._id}`,
                    children: modalBody(mi)
                }
            }))
        } else {
            setCollapseItems([])
        }

        setIsLoading(false)
    }, [migrations])

    useEffect(() => {
        setIsLoading(true)

        const promiseAsync = async (connectionId) => {
            const d = await Connections.getConnectionLogs({ connectionId })
            setMigrations(d)
        }

        if(!connectionId) {
            return setMigrations([])
        }

        promiseAsync(connectionId)

        return () => {}
    }, [connectionId])

    return (
        <Modal
            title={Locale('migrations history')}
            centered
            open={open}
            width={1000}
            onCancel={() => {
                handleOpen(false)
                setConnectionId(null)
            }}
            cancelText={Locale('close')}
            okButtonProps={{
                style: {
                    display: "none"
                }
            }}
        >
            {isLoading && <Skeleton loading={isLoading} active /> || ((!isLoading && collapseItems?.length) && (
                <Collapse accordion bordered={false}>
                {
                    collapseItems.map(mi => {
                        return (
                            <Panel header={mi.label} key={mi.key}>
                                {mi.children}
                            </Panel>
                        )
                    })
                }
                </Collapse>
            ) || <Typography.Title level={5} style={{ textAlign: 'center' }}>{Locale('no data to display')}</Typography.Title>)}
        </Modal>
    )
} 