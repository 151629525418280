import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form
} from 'antd'
import { Notification } from "../.."
import { Groups } from "../../../modules"
import { Locale } from "../../../helpers/locale"

const { Item } = Form

export default function GroupModal ({ title = '', open = false, handleOpen = () => {}, editGroup = {}, mutating = () => {} }) {
    const [group, setGroup] = useState({
        name: editGroup?.name || ''
    }),
    [loading, setLoading] = useState(false),
    isEditModal = Object.keys(editGroup)?.length > 1 ? true : false

    const handleSave = async () => {
        setLoading(true)

        if(!group.name?.length) {
            setLoading(false)
            return Notification({ 
                description: Locale('the name field is required'),
                title: Locale('groups'),
                type: 'warning'
            })
        }

        if(isEditModal) {
            await Groups.updateClientGroup({ group, id: group?._id })
        } else {
            await Groups.createClientGroup({ group })
        }
        
        setLoading(false)
        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? Locale('edited') : Locale('created')
        
        Notification({ 
            description: `${Locale('group')} ${modalType} ${Locale('with success')}`,
            title: Locale('groups')
        })
    }

    const handleValue = (e, key) => {
        group[key] = e.target.value

        setGroup({ ...group })
    }

    useEffect(() => {
        setGroup({ ...editGroup })
    }, [editGroup])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={() => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => await handleSave()}
            okText={Locale('save')}
            cancelText={Locale('cancel')}
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            <Form 
                layout={'vertical'}
                initialValues={{
                    ...group
                }}
            >
                <Item label={Locale('name')}>
                    <Input placeholder={Locale('group name')} onChange={(e) => handleValue(e, 'name')} value={group?.name} />
                </Item>
            </Form>
        </Modal>
    )
} 