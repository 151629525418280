import React, { useState } from "react"
import { 
    Typography,
    Form,
    Input,
    Col,
    Button
} from 'antd'
import { Notification } from "../../components"
import { Locale } from "../../helpers/locale"

const { Item } = Form

export default function PageTwoSetup ({ state = {}, handleState = () => {}, handlePage = () => {} }) {
    const [componentState, setComponentState] = useState({
        name: '',
        cnpj_cpf: '',
        phone: '',
        address: ''
    })

    const handleValue = (e, key) => {
        const value = (key == 'phone' || key == 'cnpj_cpf') ? e.target.value.replace(/[^a-zA-Z0-9]/g, '').replace(' ', '') : e.target.value
        console.log(value)
        if(key == 'phone' || key == 'cnpj_cpf') {
            const isNumeric = typeof Number(value) == 'number' ?? null,
                language = localStorage.getItem('@sys_language')

            if(language == 'pt-BR') {
                if((!isNumeric && isNumeric != null) || value.length > 14) {
                    return Notification({
                        type: 'warning',
                        description: `O campo ${key == 'cnpj_cpf' ? key.replace('_', ' ') : 'telefone'} suporta apenas números e máximo de 14 caracteres`
                    })
                }   
            } else if (language == 'en-US') {
                if(key == 'cnpj_cpf' && ((!isNumeric && isNumeric != 0) || value.length > 9)) {
                    return Notification({
                        type: 'warning',
                        description: `The field ${'EIN'} support only 9 caracteres`
                    })
                }   
            }
        }   

        componentState[key] = value 

        setComponentState({ ...componentState })
    }

    const handleContinue = () => {
        let isOK = true,
            errorsBag = []

        Object.keys(componentState).forEach(key => {
            const value = componentState[key],
                language = localStorage.getItem('@sys_language')

            switch (key) {
                case 'name':
                    isOK = value?.length >= 1 && value?.length <= 20
                    if(!isOK) errorsBag.push(<p>{Locale('the company name is required and have a length between 1 and 20')}</p>)
                    break
                case 'cnpj_cpf':
                    isOK = (language == 'pt-BR' && value?.length >= 11 && value?.length <= 14) || value?.length < 10
                    if(!isOK) errorsBag.push(<p>{Locale('the ein is required')}</p>)
                    break
                case 'phone':
                    isOK = value?.length == 11 || value?.length == 10
                    if(!isOK) errorsBag.push(<p>{Locale('the phone is required')}</p>)
                    break
                case 'address':
                    isOK = value?.length >= 5
                    if(!isOK) errorsBag.push(<p>{Locale('the address is required')}</p>)
                    break
            }
        })

        if(!isOK) return Notification({
            title: Locale('continue'),
            type: 'warning',
            description: errorsBag
        })

        state = {
            ...state,
            ...componentState
        }

        handleState({ ...state })
        handlePage(3)
    }

    const getFormats = (field) => {
        const language = localStorage.getItem('@sys_language')
        
        let formatedValue = componentState[field]

        switch (language) {
            case 'pt-BR':
                switch (field) {
                    case 'cnpj_cpf':
                        if(componentState.cnpj_cpf?.length == 11) {
                            formatedValue = componentState.cnpj_cpf.replace(/^((\d{3})(\d{3})(\d{3})(\d{2}))*$/, "$2.$3.$4-$5")
                        } else if (componentState.cnpj_cpf?.length == 14) {
                            formatedValue = componentState.cnpj_cpf.replace(/^((\d{2})(\d{3})(\d{3})(\d{4})(\d{2}))*$/, "$2.$3.$4/$5-$6")
                        }
                        break
                    case 'phone':
                        if(componentState.phone?.length == 11) {
                            formatedValue = componentState.phone.replace(/^((\d{2})(\d{5})(\d{4}))*$/, "($2) $3-$4")
                        } else if (componentState.phone?.length == 10) {
                            formatedValue = componentState.phone.replace(/^((\d{2})(\d{4})(\d{4}))*$/, "($2) $3-$4")
                        }
                        break
                }
                break
            case 'en-US':
                switch (field) {
                    case 'cnpj_cpf':
                        if(componentState.cnpj_cpf?.length == 9) {
                            formatedValue = componentState.cnpj_cpf.replace(/^((\d{2})(\d{7}))*$/, "$2-$3")
                        }
                        break
                    case 'phone':
                        if(componentState.phone?.length == 10) {
                            formatedValue = componentState.phone.replace(/^((\d{3})(\d{3})(\d{4}))*$/, "($3) $2-$4")
                        }
                        break
                }
                break
        }

        return formatedValue
    }

    return (
        <>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                {Locale('type the infos below')}
            </Typography.Title>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Form layout="vertical" style={{ width: '100%' }}>
                    <Item label={Locale('company name')}>
                        <Input 
                            value={componentState.name}
                            onChange={(e) => handleValue(e, 'name')}
                        />
                    </Item>
                    <Item label={Locale('ein')}>
                        <Input 
                            value={componentState.cnpj_cpf?.length > 0 ? getFormats('cnpj_cpf') : ''}
                            onChange={(e) => handleValue(e, 'cnpj_cpf')}
                        />
                    </Item>
                    <Item label={Locale('phone')}>
                        <Input 
                            value={componentState.phone?.length > 0 ? getFormats('phone') : ''}
                            style={{ width: '100%' }} 
                            onChange={(e) => handleValue(e, 'phone')}
                        />
                    </Item>
                    <Item label={Locale('address')}>
                        <Input 
                            value={componentState.address}
                            onChange={(e) => handleValue(e, 'address')}
                        />
                    </Item>
                </Form>
            </Col>
            <div 
                style={{
                    float: 'right'
                }}
            >
                <Button 
                    style={{ 
                        float: 'right',
                        marginRight: 17
                    }} 
                    onClick={handleContinue}
                    shape="round" size={'large'}
                >
                    {Locale('continue')}
                </Button>
            </div>
        </>
    )
} 