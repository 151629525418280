import React, { useEffect, useState } from 'react'
import 'antd/dist/reset.css'
import Router from './router'
import { useSWRConfig, SWRConfig } from 'swr'

function App() {
  const [logged, setLogged] = useState(false),
    [currentPage, setCurrentPage] = useState(<></>)

  const { cache } = useSWRConfig()

  const renderPage = () => {
    if(logged) {
      setCurrentPage(Router.getStartedPage({ setCurrentPage }))
    }
    if(!logged) setCurrentPage(Router.loginPage({ setCurrentPage, setLogged }))
  }

  window.addEventListener('logout', () => {
    setLogged(false)

    cache.delete('@connections')
    cache.delete('@apps')
    cache.delete('@groups')

    localStorage.removeItem('@appsUpdates')
    localStorage.removeItem('@token')
    localStorage.removeItem('@refresh_token')
    localStorage.removeItem('@token_expires')
    localStorage.removeItem('@user')
  })

  useEffect(() => {
    renderPage()
  }, [logged])

  useEffect(() => {
    if(localStorage.getItem('@token')) {
      return setLogged(true)
    }

    // if(window.location.href.match(/sandbox/) && !logged) {
    //   return renderPage()
    // }
    
    setCurrentPage(Router.loginPage({ setCurrentPage, setLogged }))
  }, [])

  return (
    currentPage
    // <SWRConfig 
    //   value={{
    //     refreshInterval: 1000 * 120,
    //     revalidateOnFocus: true,
    //     revalidateOnMount: false,
    //     revalidateOnReconnect: true,
    //     refreshWhenOffline: false,
    //     refreshWhenHidden: false,
    //     revalidateIfStale: true,
    //     // provider: (cache) => cache
    //   }}
    // >
      // {currentPage}
    // </SWRConfig>
  )
}

export default App;
