import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Button,
    Row,
    Col
} from 'antd'
import useSWR, { useSWRConfig } from 'swr'
import { 
    Table,
    GroupModal,
    DestroyGroupModal
} from '../../components'
import {
    PERMISSIONS
} from '../../environment'
import {
    PrincipalContainer
} from '../../containers'
import { Groups } from '../../modules'
import { 
    DeleteFilled,
    EditFilled
} from '@ant-design/icons'
import { Locale } from '../../helpers/locale'

const { Link } = Typography

export default function GroupsPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    tableColumns = [
        {
            title: Locale('name'),
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: Locale('actions'),
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                        <Link onClick={() => handleEditModal(row)} href='#'>
                            <EditFilled  style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                        <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                            <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Link>
                    )}
                </Space>
            ),
        },
    ]

    const mutating = async () => {
        await mutate('@groups', () => Groups.getClientGroups({}))

        const newCache = cache.get('@groups')?.data ?? []

        setTableData(newCache?.map((group, i) => {
            return {
                key: String(i),
                ...group
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const swrOptions = {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
    }

    const { data: groupsData, isLoading: groupsIsLoading } = useSWR('@groups', () => Groups.getClientGroups({}), swrOptions)

    const handleEditModal = (group) => {
        setEditData({ ...group })
        setOpenEditModal(true)
    }

    const handleDestroyModal = (group) => {
        setDestroyData({ ...group })
        setOpenDestroyModal(true)
    }

    const handleCreateModal = () => {
        setOpenCreateModal(true)
    }

    const handleEditModalOpen = () => {
        return <GroupModal 
            title={`${Locale('editing')} ${Locale('group')}: ${editData?.name}`} 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            editGroup={editData} 
            mutating={mutating}
        />
    }

    const handleCreateModalOpen = () => {
        return <GroupModal 
            title={Locale('creating new group')} 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            editGroup={{}} 
            mutating={mutating}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyGroupModal
            title={`${Locale('exclude')} ${Locale('group')}`} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        setTableData(groupsData?.data?.map((group, i) => {
            return {
                key: String(i),
                ...group
            }
        }) || [])
        setIsLoading(false)
    }, [groupsIsLoading])

    useEffect(() => {
        mutating()
    }, [])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='2' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        {Locale('groups')}
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}