import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Row,
    Col,
    Select
} from 'antd'
import useSWR, { useSWRConfig } from 'swr'
import { 
    Table,
    PullRequestModal,
    NotificationModal
} from '../../components'
import {
    PERMISSIONS
} from '../../environment'
import {
    PrincipalContainer
} from '../../containers'
import { PullRequest, Apps } from '../../modules'
import { 
    EditFilled
} from '@ant-design/icons'
import { Locale } from '../../helpers/locale'

const { Link } = Typography

export default function PullRequestPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const { data: pullRequestData, isLoading: pullRequestsIsloading } = cache.get('@pull_requests') ?? { data: [] }
    const { data: appsData, isLoading: appsIsloading } = cache.get('@apps') ?? { data: [] }
    const { data: workersData, isLoading: workersIsloading } = cache.get('@workers') ?? { data: [] }

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [filterApp, setFilterApp] = useState(null),
    [appsToFilter, setAppsToFilter] = useState([]),
    tableColumns = [
        {
            title: Locale('name'),
            dataIndex: 'app_name',
            key: 'app_name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: Locale('type'),
            dataIndex: 'type',
            key: 'type',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {row.worker_id ? 'Worker' : 'API'}
                </>
            ),
        },
        {
            title: Locale('environment'),
            dataIndex: 'environment',
            key: 'environment',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {row.environment.toUpperCase()}
                </>
            ),
        },
        {
            title: 'HTTP Header',
            dataIndex: 'request_type',
            key: 'request_type',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <>
                    {row.request_type?.toUpperCase() ?? '-'}
                </>
            ),
        },
        {
            title: Locale('actions'),
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                const pr = tableData.find(pr => pr?._id === row?._id) || {}

                return (
                    <Space size="middle">
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                            <Link onClick={() => handleEditModal(pr)} href='#'>
                                <EditFilled  style={{ fontSize: '16px' }} />
                            </Link>
                        )}
                    </Space>
                )
            },
        },
    ]

    const handleEditModal = (pr) => {
        setEditData({ ...pr })
    }

    const mutating = async () => {
        await mutate('@apps', Apps.getClientApps({}))
        await mutate('@pull_requests', PullRequest.getPullRequests())

        // setTableData([])

        setAppsToFilter(appsData?.map((app, i) => {
            return {
                label: app.name,
                value: app._id
            }
        }) || [])

        setEditData({})

        setIsLoading(false)
    }

    const handleEditModalOpen = () => {
        return <PullRequestModal 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            pullRequestData={editData} 
            mutating={mutating}
            prId={editData?._id}
            setEditData={setEditData}
        />
    }

    const filterByApp = (appId) => {
        setFilterApp(appId)
        setIsLoading(true)
    }

    useEffect(() => {
        if(Object.keys(editData).length) {
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    // useEffect(() => {
    //     if(!tableData?.length) {
    //         setTableData(pullRequestData?.map((pull, i) => {
    //             const app = appsData?.find(appData => appData._id == pull.app_id)
    //             return {
    //                 key: String(i),
    //                 app_name: app?.name,
    //                 ...pull
    //             }
    //         }) || [])
    //     }
    // }, [tableData])

    useEffect(() => {
        if(appsData?.length) {
            setTableData(pullRequestData?.map((pull, i) => {
                const app = appsData?.find(appData => appData._id == pull.app_id) 
                    || workersData?.find(worker => worker._id == pull.worker_id)

                return {
                    key: String(i),
                    app_name: app?.name,
                    ...pull
                }
            }) || [])
        }
        
        setIsLoading(false)
    }, [pullRequestData, pullRequestsIsloading])

    useEffect(() => {
        if(filterApp?.length) mutating()
    }, [filterApp])

    useEffect(() => {
        setAppsToFilter(appsData?.map((app, i) => {
            return {
                label: app.name,
                value: app._id
            }
        }) || [])

        setIsLoading(false)
    }, [appsData])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='10' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Pull Requests
                        <Select
                            defaultValue={''}
                            style={{
                                width: 150,
                                marginLeft: 15
                            }}
                            onSelect={(e) => filterByApp(e)}
                            options={[
                                {
                                    label: Locale('filter by api/worker'),
                                    value: ''
                                },
                                ...appsToFilter
                            ]}
                        />
                    </Typography.Title>
                    <Row>
                        <Col style={{ overflow: 'scroll' }} span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}