import React, { useState } from "react"
import { 
    Button,
    Layout,
    Row,
    Steps,
    Col
} from 'antd'
import PageOne from "./page-one"
import PageTwo from "./page-two"
import PageThree from "./page-three"
import PageFour from "./page-four"
import QueueAnim from 'rc-queue-anim'
import Router from '../../router'
import { Locale } from "../../helpers/locale"

const { Content } = Layout

export default function SetupPage ({ setCurrentPage, setLogged }) {
    const [setup, setSetup] = useState({
        serial: null,
        name: '',
        cnpj_cpf: '',
        phone: '',
        address: ''
    }),
    [page, setPage] = useState(4),
    [isLoading, setIsLoading] = useState(false)

    const handlePage = (newPage) => {
        setPage(newPage)
        setIsLoading(true)
    }

    const renderPage = () => {
        let currentPage = <></>
        
        switch (page) {
            case 1:
                currentPage = <PageOne 
                    state={setup}
                    handlePage={handlePage}
                    handleState={setSetup}
                />
                break
            case 2:
                currentPage = <PageTwo 
                    state={setup}
                    handlePage={handlePage}
                    handleState={setSetup}
                />
                break
            case 3:
                currentPage = <PageThree 
                    state={setup}
                    handlePage={handlePage}
                    handleState={setSetup}
                />
                break
            case 4:
                currentPage = <PageFour 
                    state={setup}
                    handlePage={handlePage}
                    token={setup.token}
                />
                break
            case 5:
                return setCurrentPage(Router.loginPage({ setCurrentPage, setLogged }))
        }

        return currentPage
    }

    return (
        <Layout>
            <Content
                className="site-layout"
                style={{
                    background: 'radial-gradient(circle, rgba(174,196,238,1) 0%, rgba(154,154,154,1) 100%)'
                }}
            >
                <Row 
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                    justify='center' 
                    align="middle"
                    style={{ 
                        backgroundColor: 'transparent',
                        minHeight: '100vh'
                    }}
                >
                    <QueueAnim 
                        key="animation"
                        type={['right', 'left']}
                        ease={['easeOutQuart', 'easeInOutQuart']}
                        onEnd={() => {
                            setIsLoading(false)
                        }}
                    >
                        <Steps
                            key={`a`}
                            size="small"
                            current={page - 1}
                            items={[
                                {
                                    title: Locale('verify serial'),
                                },
                                {
                                    title: Locale('infos'),
                                },
                                {
                                    title: Locale('user'),
                                },
                                {
                                    title: Locale('finalization'),
                                }
                            ]}
                        />
                        <div style={{ marginTop: '5rem', marginBottom: '5rem' }} key={`${page}`}>
                            {!isLoading && renderPage()}
                        </div>
                        {page == 1 && (<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button onClick={() => setCurrentPage(Router.loginPage({ setCurrentPage, setLogged }))} type="primary">{Locale('back to login')}</Button>
                        </Col>)}
                    </QueueAnim>
                </Row>
            </Content>
        </Layout>
    )
} 