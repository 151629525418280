import React, { useState, useEffect } from "react"
import { 
    Button,
    Tabs,
    Input
} from 'antd'
import Editor from '@monaco-editor/react'
import { Locale } from '../../../helpers/locale'

export default function DevMode ({ readOnly = false, handleValue = () => {}, values = {}, workerId = null }) {
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
    [editorCodePrd, setEditorCodePrd] = useState(''),
    [editorCodeHml, setEditorCodeHml] = useState(''),
    [editorCodeSdb, setEditorCodeSdb] = useState(''),
    [editorCodeDev, setEditorCodeDev] = useState('')

    const handleSaveCode = () => {
        const codePrd = values?.dev_mode?.prd.code
        if(editorCodePrd != codePrd) handleValue(editorCodePrd, 'dev_mode.prd.code')

        const codeHml = values?.dev_mode?.hml.code
        if(editorCodeHml != codeHml) handleValue(editorCodeHml, 'dev_mode.hml.code')

        const codeSdb = values?.dev_mode?.sdb.code
        if(editorCodeSdb != codeSdb) handleValue(editorCodeSdb, 'dev_mode.sdb.code')

        const codeDev = values?.dev_mode?.dev.code
        if(editorCodeDev != codeDev) handleValue(editorCodeDev, 'dev_mode.dev.code')

        setSaveButtonDisabled(true)
    }

    useEffect(() => {
        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [workerId])

    useEffect(() => {
        setSaveButtonDisabled(true)

        if((values?.dev_mode?.prd.code[0])) {
            const codePrd = values?.dev_mode?.prd.code
            if((editorCodePrd.length != codePrd.length)) setEditorCodePrd(codePrd)
        }

        if((values?.dev_mode?.hml.code[0])) {
            const codeHml = values?.dev_mode?.hml.code
            if((editorCodeHml.length != codeHml.length)) setEditorCodeHml(codeHml)
        }

        if((values?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.dev_mode?.sdb.code
            if((editorCodeSdb.length != codeSdb.length)) setEditorCodeSdb(codeSdb)
        }

        if(values?.dev_mode?.dev.code[0]) {
            const codeDev = values?.dev_mode?.dev.code
            if((editorCodeDev.length != codeDev.length)) setEditorCodeDev(codeDev)
        } 
    }, [values, workerId])

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('production')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodePrd(newValue)
                }}
                options={{
                    autoIndent: "full",
                    readOnly
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('homolog')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeHml(newValue)
                }}
                options={{
                    autoIndent: "full",
                    readOnly
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeSdb(newValue)
                }}
                options={{
                    autoIndent: "full",
                    readOnly
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('dev')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="65vh" 
                onChange={(newValue) => {
                    if(saveButtonDisabled) {
                        setSaveButtonDisabled(false)
                    }

                    setEditorCodeDev(newValue)
                }}
                options={{
                    autoIndent: "full",
                    readOnly
                }}
            />
        </>
    ]

    return (
        <div>
            {!readOnly && (
                <>
                    <h3 style={{ textAlign: 'left' }}>
                        Dev Mode
                    </h3>
                    <h5 style={{ textAlign: 'left' }}>
                        {Locale('the code always end with a return {Object} or return {Array} to fill the response')}<br></br>
                        {Locale('after change, click in "save code" to confirm and after save a Pull Request will be open')}<br></br>
                    </h5>
                    { workerId !== null && <Input placeholder={Locale('type the Pull Request description')} value={values?.description} onChange={(e) => handleValue(e, 'pr_description')} /> }
                </>
            )}
            <Tabs
                defaultActiveKey="2"
                items={[Locale('production'), Locale('homolog'), 'Sandbox', Locale('dev')].map((tabName, i) => {
                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabsEnv[i],
                    }
                })}
            />
            {!readOnly && <Button style={{ marginTop: 10, float: 'right' }} disabled={saveButtonDisabled} type="default" shape="round" onClick={handleSaveCode}>{Locale('save code')}</Button>}
        </div>
    )
} 