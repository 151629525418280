import React, { useState, useEffect } from "react"
import { 
    Tag,
    Divider,
    Switch,
    Input,
    Form,
    Tabs,
    Select,
    Row,
    Col,
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    ThunderboltOutlined,
    CoffeeOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { Locale } from '../../../helpers/locale'

const { Item } = Form

export default function TabDelete ({ handleValue = () => {}, values = {}, isEdit = false, appId = null, endpointName = null, endpointName2 = null, workers = [] }) {
    const [endpointDisabled, setEndpointDisabled] = useState(values?.request?.status || false),
        [rawQuery, setRawQuery] = useState(false),
        [saveButtonDisabled, setSaveButtonDisabled] = useState(true),
        [editorCodePrd, setEditorCodePrd] = useState(''),
        [editorCodeHml, setEditorCodeHml] = useState(''),
        [editorCodeSdb, setEditorCodeSdb] = useState(''),
        [editorCodeDev, setEditorCodeDev] = useState('')


    const handleEnableEndpoint = () => {
        const newValue = !endpointDisabled ? true : false
        setEndpointDisabled(newValue)
        handleValue({ target: { value: Number(newValue) } }, 'request.delete.status')
    }

    const handleEnableRawQuery = () => {
        const newValue = !rawQuery ? true : false

        setRawQuery(newValue)

        if(!newValue) {
            handleValue({ target: { value: null } }, 'request.delete.raw_query')
        } else if (isEdit) {
            handleValue({ target: { value: null } }, 'request.delete.raw_query')
        }
    }

    useEffect(() => {
        setEditorCodePrd('')
        setEditorCodeHml('')
        setEditorCodeSdb('')
        setEditorCodeDev('')
    }, [appId])

    useEffect(() => {
        if(values?.request?.raw_query?.[0]?.length > 0) {
            setRawQuery(true)
        }

        if((values?.request?.dev_mode?.prd.code[0])) {
            const codePrd = values?.request?.dev_mode?.prd.code
            if((editorCodePrd.length != codePrd.length)) setEditorCodePrd(codePrd)
        }

        if((values?.request?.dev_mode?.hml.code[0])) {
            const codeHml = values?.request?.dev_mode?.hml.code
            if((editorCodeHml.length != codeHml.length)) setEditorCodeHml(codeHml)
        }

        if((values?.request?.dev_mode?.sdb.code[0])) {
            const codeSdb = values?.request?.dev_mode?.sdb.code
            if((editorCodeSdb.length != codeSdb.length)) setEditorCodeSdb(codeSdb)
        }

        if(values?.request?.dev_mode?.dev.code[0]) {
            const codeDev = values?.request?.dev_mode?.dev.code
            if((editorCodeDev.length != codeDev.length)) setEditorCodeDev(codeDev)
        }

        setSaveButtonDisabled(true)
        setEndpointDisabled(Boolean(values?.request?.status))
    }, [values, appId])

    const tabsEnv = [
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('production')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodePrd || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {}}
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('homolog')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodeHml || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {}}
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                Sandbox
            </h3>
            <Editor
                language='javascript'
                value={editorCodeSdb || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {}}
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>,
        <>
            <h3 style={{ textAlign: 'left' }}>
                {Locale('dev')}
            </h3>
            <Editor
                language='javascript'
                value={editorCodeDev || ''}
                theme='vs-dark'
                height="75vh"  
                onChange={(newValue) => {}}
                options={{
                    autoIndent: "full",
                    readOnly: true
                }}
            />
        </>
    ]

    const tabs = [
        <>
            <Divider orientation="left">
                {Locale('request')}
            </Divider>
            
            <div id={'request-params'}>
                <Item label={(<>
                    {'RAW Query'}
                    <Switch 
                        onChange={handleEnableRawQuery}
                        style={{ float: 'right', marginLeft: 10 }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={rawQuery}
                        disabled={true}
                    />
                    </>)}
                >
                    {
                        rawQuery && (
                            <>
                                <Input.TextArea 
                                    disabled={true}
                                    placeholder={'DELETE FROM {table} WHERE id = {id} AND user_id = {user_id}'}
                                    value={values?.request?.raw_query}
                                    onChange={(e) => handleValue(e, 'request.delete.raw_query')}
                                />
                            </>
                        )
                    }
                </Item>
                <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col className="gutter-row" span={24}>
                            <Item label={(<>
                                {'Worker'}
                            </>)}>
                                <Select
                                    disabled={true}
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={Locale('select worker')}
                                    loading={workers?.data?.length > 0 ? false : true}
                                    options={workers?.data?.length > 0 && [ { _id: null, name: Locale('none') }, ...workers?.data ]?.map(worker => {
                                        return {
                                            label: worker.name,
                                            value: worker._id
                                        }
                                    }) || []} 
                                    onChange={(e) => handleValue({ target: { value: e }}, 'request.delete.worker_id')}
                                    onInputKeyDown={(e) => e.preventDefault()}
                                    value={values?.request?.worker_id}
                                />
                            </Item>
                        </Col>
                    </Row>
            </div>
        </>,
        <>
            <Tabs
                defaultActiveKey="2"
                items={[Locale('production'), Locale('homolog'), 'Sandbox', Locale('dev')].map((tabName, i) => {
                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabsEnv[i],
                    }
                })}
            />
        </>
    ]

    return (
        <div>
            <h3 style={{ textAlign: 'left' }}>
                <Tag color="error">DELETE</Tag> {isEdit ? (endpointName2?.length > 0 ? `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}/${endpointName2}` : `/v1/${appId}${endpointName?.length > 0 ? `|${endpointName}` : ''}/{id}`) : `/v1/{app_id}|{endpoint_name}/{id}/?{endpoint_name2}`}
                <Switch 
                    onChange={handleEnableEndpoint}
                    style={{ float: 'right' }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={endpointDisabled}
                    disabled={true}
                />
            </h3>
        
            <Tabs
                defaultActiveKey="2"
                items={[ThunderboltOutlined, CoffeeOutlined].map((Icon, i) => {
                    const tabName = i == 0 ? 'Simple Mode' : 'Dev Mode' 
                    return {
                        label: (
                        <span>
                            <Icon />
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: tabs[i],
                    }
                })}
            />
        </div>
    )
} 