import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Row,
    Col,
    Collapse,
    Space,
    Avatar
} from 'antd'
import {
    PrincipalContainer
} from '../../containers'
import { 
    ApiOutlined, 
    CloudSyncOutlined,
    SettingOutlined,
    ForkOutlined
} from '@ant-design/icons'
import Editor from '@monaco-editor/react'
import { Locale } from '../../helpers/locale'

export default function UpdatePage ({ setCurrentPage, updatesData = [] }) {
    const [collapseData, setCollapseData] = useState([])

    useEffect(() => {
        const getIcon = (app) => {
            const icons = {
                simplerest_client_api: <ApiOutlined />,
                simpletool_replicator: <CloudSyncOutlined />,
                simpleworker_app: <SettingOutlined />,
                simpleworker_orchestrator: <ForkOutlined />            
            }
            return icons[app]
        }

        const items = Object.keys(updatesData).map((upKey, i) => {
            const up = updatesData[upKey]
            
            return {
                key: i.toString(),
                label: <Typography.Title level={4} style={{ textAlign: 'left' }}>{upKey.replaceAll('_', '-')}</Typography.Title>,
                hasUpdate: up.has_update,
                children: () => {
                    return (
                        <>
                            <div style={{ justifyContent: 'flex-start', display: 'flex', justifyItems: 'flex-start' }}>
                                <Space wrap size={16}>
                                    <Avatar style={{ float: 'left', backgroundColor: '#000', color: '#F1F5F9' }} shape="square" size={64} icon={getIcon(upKey)} />
                                    <Typography.Title level={4} style={{ textAlign: 'left' }}>
                                        {up.current_version.toUpperCase()}
                                    </Typography.Title>
                                </Space>
                            </div>
                            <div style={{ textAlign: 'left' }}>
                                {
                                    up?.changelog?.split('-').map((change, i) => {
                                        if(!i) return <Typography.Title level={5} style={{ marginTop: 16 }}>Changelog: </Typography.Title>

                                        return (<div>
                                            <Typography.Text>
                                                {`- ${change}`}
                                            </Typography.Text>
                                            <br></br>
                                        </div>)
                                    })
                                }
                                <Typography.Title level={5} style={{ marginTop: 16 }}>
                                    {Locale('is easy to update, you only need to generate a new docker image from the dockerfile below:')}
                                </Typography.Title>
                                <Editor 
                                    defaultLanguage='dockerfile'
                                    value={up?.dockerfile?.replace('/\\*', '/*')}
                                    theme='vs-dark'
                                    height="40vh" 
                                    options={{
                                        autoIndent: "full"
                                    }}
                                />
                                <Typography.Text>
                                    Ps: não esqueça de substituir as ENV VARS no Dockerfile para as do seu ambiente!    
                                </Typography.Text>
                            </div>
                        </>
                    )
                },
            }
        })

        setCollapseData([ ...items ])
    }, [])

    return (
        <>
            <PrincipalContainer menuItemSelected='11' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        {Locale('updates')}
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Collapse
                                accordion={true}
                                bordered={false}
                            >
                                {
                                    collapseData?.length && (
                                        collapseData.map(data => {
                                            if(!data.hasUpdate) return <></>

                                            return (
                                                <Collapse.Panel header={data.label}>
                                                    {data.children()}
                                                </Collapse.Panel>
                                            )
                                        })
                                    )
                                }
                            </Collapse>
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}