import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Button,
    Row,
    Col
} from 'antd'
import { useSWRConfig } from 'swr'
import {
    PERMISSIONS
} from '../../environment'
import { 
    Table,
    UserModal,
    DestroyUserModal
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { User } from '../../modules'
import { 
    DeleteFilled,
    EditFilled
} from '@ant-design/icons'
import { Locale } from '../../helpers/locale'
import useLanguage from '../../hooks/useLanguage'

const { Link } = Typography

export default function UsersPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()
    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('@user') || '{}')),
    tableColumns = [
        {
            title: Locale('name'),
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: Locale('role'),
            dataIndex: 'role',
            key: 'role',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => {
                const translateRoles = {
                    0: Locale('normal'),
                    1: 'Admin'
                }
                return (
                    translateRoles[row.role]
                )
            }
        },
        {
            title: Locale('actions'),
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                        <Link onClick={() => handleEditModal(row)} href='#'>
                            <EditFilled  style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                        <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                            <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Link>
                    )}
                </Space>
            ),
        },
    ]

    const { data: usersData, isLoading: usersIsLoading } = cache.get('@users') ?? { data: [] }

    const mutating = async () => {
        await mutate('@users', () => User.getUsers({}))

        const newCache = cache.get('@users')?.data ?? []

        setTableData(newCache?.map((conn, i) => {
            return {
                key: String(i),
                ...conn
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleEditModal = (userData) => {
        userData.password = ''
        setEditData({ ...userData })
    }

    const handleDestroyModal = (userData) => {
        setDestroyData({ ...userData })
    }

    const handleCreateModal = () => {
        setCreateData({ closer: 1 })
    }

    const handleEditModalOpen = () => {
        return <UserModal 
            title={Locale('editing user:') + ` ${editData?.name}`} 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            userData={editData} 
            mutating={mutating}
        />
    }

    const handleCreateModalOpen = () => {
        return <UserModal 
            title={Locale('creating user')} 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            userData={{}} 
            mutating={mutating}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyUserModal 
            title={Locale('exclude user')} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        setTableData(usersData?.map((user, i) => {
            if(user.name.toLowerCase() == currentUser.name.toLowerCase()) {
                return null
            }

            return {
                key: String(i),
                ...user
            }
        }).filter(u => u !== null) || [])
        setIsLoading(false)
    }, [usersIsLoading])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected={'17'} setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        {Locale('users')}
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}