import React, { useEffect, useState } from 'react'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ApiOutlined,
  DatabaseOutlined,
  TagsOutlined,
  LockOutlined,
  KeyOutlined,
  RocketOutlined,
  LogoutOutlined,
  PullRequestOutlined,
  LineChartOutlined,
  IdcardOutlined,
  UserAddOutlined,
  BellOutlined,
  ConsoleSqlOutlined,
  CodeOutlined,
  SettingOutlined,
  UserOutlined,
  ToolOutlined,
  BugOutlined,
  UnlockOutlined,
  CloudServerOutlined,
  CloudSyncOutlined,
  BookOutlined,
  ClockCircleOutlined
} 
from '@ant-design/icons'
import { 
  Layout, 
  Menu, 
  Button, 
  Avatar, 
  Typography, 
  Badge,
  Skeleton
} from 'antd'
import Router from '../../router'
import LogoCollapsed from '../../logo.svg'
import LogoEntire from '../../logo.png'
import LogoCodeTech from '../../codetech-logo.svg'
import useSWR from 'swr'
import { Update } from '../../modules'
import {
  Locale,
  setLocale
} from '../../helpers/locale'
import EUAFlag from '../../united-states-flag-icon.svg'
import BrazilFlag from '../../brazil-flag-icon.svg'
import useLanguage from '../../hooks/useLanguage'

const { Header, Content, Footer, Sider } = Layout,
  { Text } = Typography

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    }
}

export default function PrincipalContainer ({ content = <></>, setCurrentPage, menuItemSelected = '6', isLoading = false }) {
  const [isMobile, setIsMobile] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('@user')))
  const [collapsed, setCollapsed] = useState(false),
    [items, setItems] = useState([]),
    [updatesData, setUpdatesData] = useState(JSON.parse(localStorage.getItem('@appsUpdates') || '{}')),
    [collapseApisMenuIds, setCollapseApisMenuIds] = useState([
      '16',
      '12',
      '13',
      '14',
      '15',
      '3',
      '20',
      '22',
      '10',
      '19',
      '18',
      '2',
      
    ]),
    [collapseUsersMenuIds, setCollapseUsersMenuIds] = useState([
      '17',
      '8'
    ]),
    [menuItem, setMenuItem] = useState(menuItemSelected),
    [language, setLanguage] = useLanguage()

  const { data: updates } = useSWR('@updates', Update.getUpdates)

  const handleLogout = () => {
    window.dispatchEvent(new Event("logout"))
  }

  const handleMobile = () => {
    window.dispatchEvent(new Event("mobileResize"))
  }

  const handleDesktop = () => {
    window.dispatchEvent(new Event("desktopResize"))
  }

  const menuUserItemsShow = () => {
    let itemsMenu = [
      // {
      //   key: '0',
      //   icon: <HomeFilled style={{ fontSize: '16px' }} />,
      //   label: 'Ínicio',
      //   onClick: () => {
      //     setCurrentPage(Router.connectionsPage({ setCurrentPage }))
      //   }
      // },
      {
        key: '21',
        icon: <CloudSyncOutlined style={{ fontSize: '16px' }} />,
        label: Locale('magic deploy'),
        onClick: () => {
          setCurrentPage(Router.replicatorPage({ setCurrentPage, userPermissions: user?.permissions.replicator }))
        }
      },
      {
        key: '0',
        icon: <DatabaseOutlined style={{ fontSize: '16px' }} />,
        label: Locale('connections'),
        onClick: () => {
          setCurrentPage(Router.connectionsPage({ setCurrentPage, userPermissions: user?.permissions.connections }))
        }
      },
      {
        key: '1',
        icon: <ToolOutlined style={{ fontSize: '16px' }} />,
        label: Locale('tools'),
        children: [
          {
            key: '99',
            icon: <ApiOutlined style={{ fontSize: '16px' }} />,
            label: <>
              <Text style={{ fontSize: 12 }}>{'SimpleREST'}</Text>
            </>,
            children: [
              {
                key: '16',
                icon: <ApiOutlined style={{ fontSize: '16px' }} />,
                label: <>
                  <Text>{'APIs'}</Text>
                </>,
                onClick: () => {
                  setCurrentPage(Router.appsPage({ setCurrentPage, userPermissions: user?.permissions.apps }))
                }
              },
              {
                key: '18',
                icon: <UnlockOutlined style={{ fontSize: '16px' }} />,
                label: <>
                  <Text>{Locale('auth')}</Text>
                </>,
                onClick: () => {
                  setCurrentPage(Router.authPage({ setCurrentPage, userPermissions: user?.permissions.authApps }))
                }
              },
              {
                key: '20',
                icon: <CloudServerOutlined style={{ fontSize: '16px' }} />,
                label: 'CORS',
                onClick: () => {
                  setCurrentPage(Router.corsPage({ setCurrentPage, userPermissions: user?.permissions.cors }))
                }
              },
              {
                key: '3',
                icon: <LockOutlined style={{ fontSize: '16px' }} />,
                label: 'ACL',
                onClick: () => {
                  setCurrentPage(Router.aclPage({ setCurrentPage, userPermissions: user?.permissions.acl }))
                }
              }
            ]
          },
          {
            key: '98',
            icon: <SettingOutlined style={{ fontSize: '16px' }} />,
            label: <>
              <Text style={{ fontSize: 12 }}>{'SimpleWORKER'}</Text>
            </>,
            children: [
              {
                key: '15',
                icon: <SettingOutlined style={{ fontSize: '16px' }} />,
                label: <>
                  <Text>{'Workers'}</Text>
                </>,
                onClick: () => {
                  setCurrentPage(Router.workersPage({ setCurrentPage, userPermissions: user?.permissions.workers }))
                }
              },
              {
                key: '22',
                icon: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                label: <>
                  <Text>{'Tasks'}</Text>
                </>,
                onClick: () => {
                  setCurrentPage(Router.tasksPage({ setCurrentPage, userPermissions: user?.permissions?.tasks }))
                }
              },
            ]
          },
          {
            key: '19',
            icon: <BugOutlined style={{ fontSize: '16px' }} />,
            label: <>
              <Text style={{ fontSize: 12 }}>{'SimpleLOGS'}</Text>
            </>,
            onClick: () => {
              setCurrentPage(Router.logsPage({ setCurrentPage, userPermissions: user?.permissions.logs }))
            }
          },
          {
            key: '14',
            icon: <KeyOutlined style={{ fontSize: '16px' }} />,
            label: <>
              <Text style={{ fontSize: 12 }}>{'SimpleKEYS'}</Text>
            </>,
            onClick: () => {
              setCurrentPage(Router.keysPage({ setCurrentPage, userPermissions: user?.permissions.keys }))
            }
          },
          {
            key: '13',
            icon: <CodeOutlined style={{ fontSize: '16px' }} />,
            label: <>
              <Text style={{ fontSize: 12 }}>{'SimpleLIB'}</Text>
            </>,
            onClick: () => {
              setCurrentPage(Router.libsPage({ setCurrentPage, userPermissions: user?.permissions.libs }))
            }
          },
          {
            key: '10',
            icon: <PullRequestOutlined style={{ fontSize: '16px' }} />,
            label: 'Pull Requests',
            onClick: () => {
              setCurrentPage(Router.pullRequestPage({ setCurrentPage, userPermissions: user?.permissions.pull_request }))
            }
          },
          {
            key: '12',
            icon: <ConsoleSqlOutlined style={{ fontSize: '16px' }} />,
            label: <>
              <Text>{'Migrations'}</Text>
            </>,
            onClick: () => {
              setCurrentPage(Router.migrationPage({ setCurrentPage, userPermissions: user?.permissions.migrations }))
            }
          },
          {
            key: '2',
            icon: <TagsOutlined style={{ fontSize: '16px' }} />,
            label: Locale('groups'),
            onClick: () => {
              setCurrentPage(Router.groupsPage({ setCurrentPage, userPermissions: user?.permissions.group }))
            }
          }
        ]
      },
      // {
      //   key: '5',
      //   icon: <FundFilled style={{ fontSize: '16px' }} />,
      //   label: 'Relatórios',
      //   onClick: () => {
      //     setCurrentPage(Router.groupsPage({ setCurrentPage }))
      //   }
      // },
      {
        key: '4',
        icon: <LineChartOutlined style={{ fontSize: '16px' }} />,
        label: 'Relatório Mensal',
        onClick: () => {
          setCurrentPage(Router.billingPage({ setCurrentPage, userPermissions: user?.permissions }))
        },
        style: {
          display: 'none'
        }
      },
      {
        key: '5',
        label: '',
        onClick: () => {
          setCurrentPage(Router.profilePage({ setCurrentPage, user }))
        },
        style: {
          display: 'none'
        }
      },
      {
        key: '6',
        icon: <RocketOutlined style={{ fontSize: '16px' }} />,
        label: 'Tutorial',
        onClick: () => {
          setCurrentPage(Router.getStartedPage({ setCurrentPage }))
        }
      },
      {
        key: '97',
        icon: <BookOutlined style={{ fontSize: '16px' }} />,
        label: 'Docs',
        onClick: () => {
          window.open('https://simpletool-docs.atlassian.net/wiki/spaces/~71202014694e9de5ae4e81a188b783f58b0b9f/pages/2392065/M+dulo+de+API', '_blank');
        }
      },
      {
        key: '9',
        icon: <UserOutlined style={{ fontSize: '16px' }} />,
        label: Locale('manage users'),
        children: [
          {
            key: '17',
            icon: <UserAddOutlined style={{ fontSize: '16px' }} />,
            label: Locale('users'),
            onClick: () => {
              setCurrentPage(Router.usersPage({ setCurrentPage, userPermissions: user?.permissions.users }))
            }
          },
          {
            key: '8',
            icon: <IdcardOutlined style={{ fontSize: '16px' }} />,
            label: Locale('users policy groups'),
            onClick: () => {
              setCurrentPage(Router.usersPoliciesPage({ setCurrentPage, userPermissions: user?.permissions.users_policies }))
            }
          },
        ]
      },
      // {
      //   key: '7',
      //   icon: <DollarCircleFilled style={{ fontSize: '16px' }} />,
      //   label: 'Transações',
      //   onClick: () => {
      //     setCurrentPage(Router.transactionsPage({ setCurrentPage, userPermissions: user?.permissions.transaction }))
      //   }
      // },
    ],
    viewsPermissions = {
      "replicator": '21',
      "connections": '0',
      "apps": '1',
      "workers": '15',
      "group": '2',
      "acl": '3',
      "billing": '4',
      // "transaction": '7',
      "users_policies": '8',
      "users": '9',
      "perfil": '5',
      "pull_request": '10',
      "migrations": '12',
      "libs": '13',
      "keys": '14',
      "update": '11',
      "tutorial": '6',
      "logs": '19',
      "cors": '20',
      "auth": '18',
      "tasks": '22',
      "docs": '97'
    },
    views = []

    const isSandbox = false

    if(!isSandbox) {
      let hasUpdate = true
  
      const updatesApps = Object.keys(updatesData)
  
      updatesApps.every(key => {
        hasUpdate = updatesData?.[key]?.has_update
  
        return true
      })
  
      if(hasUpdate) {
        let countAppsToUpdate = 0
  
        updatesApps.filter(appKey => updatesData[appKey].has_update ? countAppsToUpdate++ : null)
        itemsMenu.push({
          key: '11',
          icon: <Badge count={countAppsToUpdate} size="small">
            <BellOutlined style={{ fontSize: '16px' }} />
          </Badge>,
          label: <Text style={{ color: 'black' }}>{Locale('updates')}</Text>,
          onClick: () => {
            setCurrentPage(Router.updatePage({ setCurrentPage, updatesData }))
          }
        })
      }
    }

    Object.keys(viewsPermissions).forEach(key => {
      const viewKey = viewsPermissions?.[key]
      const hasPermission = user?.permissions?.[key]?.length > 0

      const skip = [
        'perfil',
        'tutorial',
        'update',
        'libs',
        'docs'
      ]

      if(hasPermission || skip.includes(key)) {
        let view = itemsMenu.find(im => im.key == viewKey)
        
        if(!views?.length) setMenuItem(viewKey)
        if(isSandbox && key == 'update') return

        views.push(view)
      }
    })

    return views
  }

  const getOpenMenu = (menuItem) => {
    let value = []

    switch (menuItem) {
      case '17':
      case '8':
        value = ['9']
        break
      case '11':
      case '6':
      case '21':
      case '0':
        value = []
        break
      case '16':
      case '18':
      case '20':
      case '3':
        value = ['1', '99']
        break
      case '22':
      case '15':
        value = ['1', '98']
        break
      default:
        value = ['1']
        break
    }

    return value
  }

  useEffect(() => {
    if(updates) localStorage.setItem('@appsUpdates', JSON.stringify({ ...updates }))

    if(!window.location.href.match(/sandbox/)) menuUserItemsShow()
  }, [updates, updatesData])

  useEffect(() => {
    const defaultOpenKey = getOpenMenu(menuItemSelected)
    setMenuItem([menuItemSelected, defaultOpenKey])
  }, [menuItemSelected])

  useEffect(() => {
    setItems(menuUserItemsShow())
    let page = items?.find(i => i?.key == menuItemSelected)?.onClick()

    if(!page) {
      const pages = items?.filter(item => item?.children?.length)
      
      if(pages?.length) {
        pages.forEach(p => {
          p.children.forEach(child => {
            if(child.key == menuItemSelected) page = child
          })
        })
      }

      page?.onClick()
    }
  }, [language])

  useEffect(() => {
    if(!items?.length) {
      const userMenuItems = menuUserItemsShow()
      setItems(userMenuItems)
    }
  }, [user])

  useEffect(() => {
    const handleResize = () => {
      const {
        width
      } = getWindowDimensions()

      let isMobileMonitor = false

      if(width <= 768) isMobileMonitor = true

      setIsMobile(isMobileMonitor)
    } 

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if(isMobile) return handleMobile()

    handleDesktop()
  }, [isMobile])

  return (
    <Layout hasSider key={menuItem}>
      <Sider
        style={{
          backgroundColor: '#F5F6F7',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed'
        }}
        trigger={<div style={{ backgroundColor: '#F5F6F7' }}><LogoutOutlined onClick={() => handleLogout()} style={{ color: '#ff4d4f', marginRight: '-10px' }} /><Button type="link" size='large' onClick={() => handleLogout()} danger>{Locale('logout')}</Button></div>} 
        collapsible 
        collapsed={collapsed}
        breakpoint={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']}
      >
      <>
        <div className="logo">
          <img style={{ 
            paddingTop: !collapsed ? '1rem' : '0',
            width: collapsed ? '5rem' : '10rem', 
            display: 'block',
            margin: '0 auto'
          }} src={collapsed ? LogoCollapsed : LogoEntire} />
        </div>
        <Skeleton loading={isLoading} active={isLoading}>
          <Menu 
            style={{ backgroundColor: '#F5F6F7', marginTop: 15 }} 
            mode="inline" 
            selectedKeys={[menuItemSelected]} 
            activeKey={menuItemSelected} 
            items={items} 
            defaultOpenKeys={[
              ...getOpenMenu(menuItemSelected)
            ]}
          />
        </Skeleton>
      </>
      </Sider>
      <Layout
        style={{
          backgroundColor: '#fff',
          marginLeft: collapsed ? 80 : 200
        }}
      >
        <Header
          style={{
            backgroundColor: '#F5F6F7',
            padding: 0,
          }}
        >
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            style: {
              marginLeft: '15px'
            },
            onClick: () => setCollapsed(!collapsed)
          })}
          {menuItemSelected == '6' && <Text style={{ fontWeight: 600, fontSize: !collapsed ? (isMobile ? '0rem' : '1.5rem') : '1.20rem', verticalAlign: '-3px', lineHeigth: '54px', marginLeft: '1rem' }}>
            {Locale('welcome')} {user?.name || Locale('user')}
          </Text>}
          {(isMobile && (menuItemSelected != '6')) || (!isMobile) && (<>
            {/* {((isMobile && collapsed && user?.permissions.credits) || (!isMobile && user?.permissions.credits)) && (
              <div style={{ float: 'right', marginRight: 15, verticalAlign: '-2px', fontWeight: 600 }}>
                <Tooltip placement="bottomLeft" title={`Créditos disponíveis (atualiza diariamente)`}>
                  <WalletFilled style={{ fontSize: '16px', marginRight: 5, verticalAlign: '-3px', color: (user?.credits >= 500 && user?.credits <= 1000) ? '#E9A806' : (user?.credits < 500 ? 'red' : 'green') }} />
                  <Text style={{ color: (user?.credits >= 500 && user?.credits <= 1000) ? '#E9A806' : (user?.credits < 500 ? 'red' : 'green') }}>
                    {user?.credits?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </Text>
                </Tooltip>
                <Tooltip  placement="bottomLeft" title={`Entre em contato para adicionar mais créditos`}>
                  <a href="https://api.whatsapp.com/send?phone=5511913034908" target={'BLANK'}>
                    <PlusOutlined style={{ marginLeft: 5 }} />
                  </a>
                </Tooltip>
              </div>
            )} */}
            <div style={{ float: 'right' }}> 
              <a style={{ textDecoration: 'none', color: '#000' }} onClick={() => setLocale('en-US', setLanguage)}>
                <img src={EUAFlag} width={15} />
              </a>
              <a style={{ marginLeft: 5, marginRight: 10, textDecoration: 'none', color: '#000' }} onClick={() => setLocale('pt-BR', setLanguage)}>
                <img src={BrazilFlag} width={15} /> 
              </a>
            </div>
            <Avatar
              onClick={() => setCurrentPage(Router.profilePage({ setCurrentPage, user }))}
              style={{ 
                float: 'right', 
                marginRight: '15px', 
                marginTop: '12px', 
                cursor: 'pointer' 
              }}
              size={{
                xs: 40,
                sm: 40,
                md: 40,
                lg: 40,
                xl: 40,
                xxl: 40,
              }}
              src={user?.photo}
              icon={<UserOutlined />}
            />
            </>)}
        </Header>
        <Content
          style={{
            margin: '24px 16px 0',
            overflow: 'initial',
          }}
        >
          <div
            style={{
              padding: 24,
              textAlign: 'center',
              minHeight: '100vh'
            }}
          >
            {content}
          </div>
        </Content>
        <Footer
          style={{
            backgroundColor: '#fff',
            textAlign: 'center',
          }}
        >
          <img src={LogoCodeTech} style={{ width: '5rem' }} />
          <span style={{ verticalAlign: '-3px', marginLeft: '5px' }}>
            &copy; {(new Date()).getFullYear()}
          </span>
        </Footer>
      </Layout>
    </Layout>)
}