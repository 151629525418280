import { useReducer } from "react"

const useLanguage = () => {
    const localLanguage = localStorage.getItem('@sys_language')

    const [language, setLanguage] = useReducer(x => localLanguage, localLanguage)
    
    return [language, setLanguage]
}

export default useLanguage