import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Checkbox,
    Row,
    Col,
    Space
} from 'antd'
import { Table, Notification } from "../.."
import { UserPolicies } from "../../../modules"
import { PERMISSIONS } from "../../../environment"
import { Locale } from "../../../helpers/locale"

const { Item } = Form

export default function UserPolicyModal ({ title = '', open = false, handleOpen = () => {}, userPolicyData = {}, mutating = () => {} }) {
    const [userPolicy, setUserPolicy] = useState({
        name: userPolicyData?.name || '',
        permissions: {
            apps: [],
            connections: [],
            acl: [],
            billing: [],
            group: [],
            // transaction: [],
            users: [],
            credits: [],
            users_policies: [],
            pull_request: [],
            migrations: [],
            workers: [],
            authApps: [],
            keys: [],
            lib: [],
            logs: [],
            cors: []
        }
    }),
    [loading, setLoading] = useState(false),
    isEditModal = userPolicyData?._id ? true : false,
    tableColumns = [
        {
            title: '',
            dataIndex: 'display_name',
            key: 'display_name',
            rowScope: 'row',
            responsive: [ 'xs', 'sm', 'md', 'lg' ]
        },
        {
            title: Locale('list'),
            dataIndex: 'list',
            key: 'list',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                const onChange = () => {
                    return row.name == 'apps' || row.name == 'connections' || row.name == 'acl' || row.name == 'group'
                    ? handleAppsList(row.name, row.list)
                    : handleValue({ target: { value: PERMISSIONS.PERMISSION_LIST } }, `permissions.${row.name}`, 'array', row.list)
                }

                return (
                    <Space size="middle">
                        <Checkbox 
                            checked={row.list} 
                            onChange={onChange} 
                        />
                    </Space>
                )
            },
        },
        {
            title: Locale('create'),
            dataIndex: 'create',
            key: 'create',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                const skipValues = [
                    'transaction',
                    'billing'
                ]

                return (
                    <>
                        {!skipValues.includes(row.name) && (
                            <Space size="middle">
                                <Checkbox 
                                    checked={row.create} 
                                    onChange={(e) => handleValue({ target: { value: PERMISSIONS.PERMISSION_CREATE } }, `permissions.${row.name}`, 'array', row.create)} 
                                />
                            </Space>
                        )}
                    </>
                )
            },
        },
        {
            title: Locale('edit'),
            dataIndex: 'edit',
            key: 'edit',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                const skipValues = [
                    'transaction',
                    'billing'
                ]

                return (
                    <>
                        {!skipValues.includes(row.name) && (
                            <Space size="middle">
                                <Checkbox 
                                    checked={row.edit} 
                                    onChange={(e) => handleValue({ target: { value: PERMISSIONS.PERMISSION_EDIT } }, `permissions.${row.name}`, 'array', row.edit)} 
                                />
                            </Space>
                        )}
                    </>
                )
            },
        },
        {
            title: Locale('delete'),
            dataIndex: 'delete',
            key: 'delete',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) =>  {
                const skipValues = [
                    'transaction',
                    'billing'
                ]

                return (
                    <>
                        {!skipValues.includes(row.name) && (
                            <Space size="middle">
                                <Checkbox 
                                    checked={row.delete} 
                                    onChange={(e) => handleValue({ target: { value: PERMISSIONS.PERMISSION_DELETE } }, `permissions.${row.name}`, 'array', row.delete)} 
                                />
                            </Space>
                        )}
                    </>
                )
            },
        },
    ]

    const handleAppsList = (name, isChecked) => {
        if(name == 'connections' || name == 'acl' || name == 'group') {
            handleValue({ target: { value: PERMISSIONS.PERMISSION_LIST } }, `permissions.apps`, 'array', true)

            return handleValue({ target: { value: PERMISSIONS.PERMISSION_LIST } }, `permissions.${name}`, 'array', isChecked)
        }

        const hasACLList = userPolicy.permissions.acl.includes(PERMISSIONS.PERMISSION_LIST)

        if (!hasACLList) {
            handleValue({ target: { value: PERMISSIONS.PERMISSION_LIST } }, `permissions.acl`, 'array', false)
        }

        const hasConnectionsList = userPolicy.permissions.connections.includes(PERMISSIONS.PERMISSION_LIST)

        if (!hasConnectionsList) {
            handleValue({ target: { value: PERMISSIONS.PERMISSION_LIST } }, `permissions.connections`, 'array', false)
        }

        const hasGroupsList = userPolicy.permissions.group.includes(PERMISSIONS.PERMISSION_LIST)

        if (!hasGroupsList) {
            handleValue({ target: { value: PERMISSIONS.PERMISSION_LIST } }, `permissions.group`, 'array', false)
        }

        handleValue({ target: { value: PERMISSIONS.PERMISSION_LIST } }, `permissions.apps`, 'array', isChecked)
    }

    const handleSave = async () => {
        setLoading(true)

        if(!userPolicy.name?.length) {
            setLoading(false)
            return Notification({ 
                description: Locale('the name is required'),
                title: Locale('users policies'),
                type: 'warning'
            })
        }

        let isError = false

        if(isEditModal) {
            const response = await UserPolicies.updateUserPolicy({ policy: userPolicy, id: userPolicyData?._id })

            isError = !response?.modifiedCount
        } else {
            const response = await UserPolicies.createUserPolicy({ policy: userPolicy })

            isError = !response?.modifiedCount
        }

        
        setLoading(false)

        if(isError) return

        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? Locale('edited') : Locale('created')
        
        Notification({ 
            description: `${Locale('users policies')} ${modalType} ${Locale('with success')}`,
            title: Locale('users policies')
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = userPolicy[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                if(isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                if(type == 'array') {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = tmpValue[splitedKeys[splitedKeys.length - 1]].filter(v => {
                        return v != value
                    })
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = value
                }
            }
        } else {
            userPolicy[key] = value
        }

        setUserPolicy({ ...userPolicy })
    }

    useEffect(() => {
        if(Object.keys(userPolicyData)?.length) setUserPolicy({ ...userPolicyData })
        else {
            setUserPolicy({
                name: userPolicyData?.name || '',
                permissions: {
                    apps: [],
                    workers: [],
                    connections: [],
                    acl: [],
                    // billing: [],
                    group: [],
                    // transaction: [],
                    users: [],
                    credits: [],
                    users_policies: [],
                    pull_request: [],
                    migrations: [],
                    authApps: [],
                    keys: [],
                    lib: [],
                    logs: [],
                    cors: []
                }
            })
        }
    }, [userPolicyData])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => await handleSave()}
            okText={Locale('save')}
            cancelText={Locale('cancel')}
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <Form 
                        layout={'vertical'}
                        initialValues={{
                            ...userPolicy
                        }}
                    >
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >

                            <Col className="gutter-row" span={24}>
                                <Item label={Locale('name')}>
                                    <Input placeholder={Locale('name')} onChange={(e) => handleValue(e, 'name')} value={userPolicy?.name} />
                                </Item>
                            </Col>
                            
                        </Row>
                        <h4 style={{ textAlign: 'left' }}>{Locale('permissions')}</h4>
                        <Row 
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                        >
                            <Col className="gutter-row" span={24}>

                                <Table
                                    pagination={false}
                                    columns={tableColumns}
                                    tableData={
                                        Object.keys(userPolicy?.permissions)
                                        .filter(key => {
                                            if(key === 'credits') return false

                                            return true
                                        })
                                        .map(key => {
                                            const localeNames = {
                                                apps: 'Apis',
                                                workers: 'Workers',
                                                connections: Locale('connections'),
                                                acl: 'ACL',
                                                // billing: 'Relatórios Mensais',
                                                group: Locale('groups'),
                                                // transaction: 'Transações',
                                                users: Locale('users'),
                                                users_policies: Locale('users policies'),
                                                authApps: Locale('authorization'),
                                                pull_request: 'Pull Request',
                                                migrations: 'Migrations',
                                                keys: 'Keys',
                                                lib: 'Libs',
                                                logs: 'Logs',
                                                cors: 'CORS'
                                            }

                                            return {
                                                display_name: localeNames[key],
                                                name: key,
                                                list: userPolicy.permissions[key]?.includes(PERMISSIONS.PERMISSION_LIST),
                                                create: userPolicy.permissions[key]?.includes(PERMISSIONS.PERMISSION_CREATE),
                                                edit: userPolicy.permissions[key]?.includes(PERMISSIONS.PERMISSION_EDIT),
                                                delete: userPolicy.permissions[key]?.includes(PERMISSIONS.PERMISSION_DELETE)
                                            }
                                        })
                                    }
                                    isLoading={false}
                                />
                                {/* <h4 style={{ textAlign: 'left', marginBottom: 10, marginTop: 10 }}>Mostrar Saldo</h4>
                                <Checkbox 
                                    checked={userPolicy.permissions.credits.includes(1)} 
                                    onChange={(e) => {
                                        const hasZero = userPolicy.permissions.credits.includes(0)
                                        const value = hasZero ? 1 : 0

                                        if(!value) handleValue({ target: { value: 1 } }, `permissions.credits`, 'array', true)
                                        else handleValue({ target: { value: 0 } }, `permissions.credits`, 'array', true)
                                         
                                        handleValue({ target: { value } }, `permissions.credits`, 'array', false)
                                    }} 
                                /> */}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
} 