import React, { useEffect, useState } from "react"
import { 
    Modal,
    Input,
    Form,
    Select,
    Tooltip,
    Tabs,
    Divider
} from 'antd'
import {
    InfoCircleOutlined
} from '@ant-design/icons'
import { Notification } from "../.."
import { Cors } from "../../../modules"
import { Locale } from '../../../helpers/locale'

const { Item } = Form

export default function CorsModal ({ isMobile = false, title = '', open = false, handleOpen = () => {}, editCors = {}, mutating = () => {} }) {
    const [cors, setCors] = useState({
        'name': null,
        'environment': {
            'dev': {
                'origins': []
            },
            'hml': {
                'origins': []
            },
            'sbd': {
                'origins': []
            },
            'prd': {
                'origins': []
            }
        }
    }),
    [roleInputValue, setRoleInputValue] = useState(''),
    [loading, setLoading] = useState(false),
    isEditModal = editCors?._id

    const handleSave = async () => {
        setLoading(true)

        if(!cors?.name?.length) {
            setLoading(false)

            return Notification({ 
                description: Locale('the name field is required'),
                title: Locale('save'),
                type: 'warning'
            })
        }

        if(isEditModal) {
            await Cors.updateClientCors({ cors, id: cors?._id })
        } else {
            await Cors.createClientCors({ cors })
        }
        
        setLoading(false)
        handleOpen(false)
        handleNotificationSuccess()
        await mutating()
    }

    const handleNotificationSuccess = () => {
        const modalType = isEditModal ? Locale('edited') : Locale('created')
        
        Notification({ 
            description: `Cors ${modalType} ${Locale('with success')}`,
            title: 'Cors'
        })
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = cors[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            if(type && !isDelete) {
                if(!value) {
                    cors[key] = []
                } else {
                    if(!Array.isArray(cors[key])) cors[key] = []
                    cors[key].push(value)
                }
            } else {
                cors[key] = value
            }
        }

        setCors({ ...cors })
    }

    const handleRemoveParam = (value, env) => {
        handleValue({ target: { value } }, `environment.${env}.origins`, 'array', true)
    }

    const mountTab = (env) => {
        return (
            <Form 
                layout={'vertical'}
                initialValues={{
                    ...cors
                }}
            >
                <Item label={
                    (<>
                        {Locale('allowed origins')}
                        <Tooltip placement="topLeft" title={Locale('the request is only allowed for the origins listed below')}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </>)
                }>
                    <Input 
                        placeholder={Locale('type the URL exactly how is coming in origin and press ENTER')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                const value = e.target.value

                                if(!value.match(/^((http|https):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)$/)) {
                                    return Notification({ 
                                        description: Locale('the origin needs to start with http:// or https:// and be a valid URL'),
                                        title: Locale('origin'),
                                        type: 'warning'
                                    })
                                }

                                handleValue(e, `environment.${env}.origins`, 'array')
                                setRoleInputValue('')
                            }
                        }}
                        value={roleInputValue}
                        onChange={(e) => setRoleInputValue(e.target.value)}
                    />
                    <Select
                        mode="tags"
                        style={{
                            width: '100%',
                            marginTop: 8
                        }}
                        bordered={false}
                        allowClear
                        showArrow={false}
                        options={cors?.environment?.[env]?.origins?.map(origin => {
                            return {
                                label: origin,
                                value: origin
                            }
                        })} 
                        onClear={(e) => handleRemoveParam(e, env)}
                        onChange={(e) => handleRemoveParam(e, env)}
                        dropdownStyle={{ display: 'none' }} 
                        onInputKeyDown={(e) => e.preventDefault()}
                        value={cors?.environment?.[env]?.origins}
                    />
                </Item>
            </Form>
        )
    }

    useEffect(() => {
        if(editCors?._id) {
            setCors({ ...editCors })
        } else {
            setCors({
                'name': null,
                'environment': {
                    'dev': {
                        'origins': []
                    },
                    'hml': {
                        'origins': []
                    },
                    'sbd': {
                        'origins': []
                    },
                    'prd': {
                        'origins': []
                    }
                }
            })
            setRoleInputValue('')
        }
    }, [editCors, open])

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={async () => {
                handleOpen(false)
            }}
            width={1000}
            onOk={async () => await handleSave()}
            okText={Locale('save')}
            cancelText={Locale('cancel')}
            cancelButtonProps={{ type: 'text' }}
            okButtonProps={{ loading: loading }}
        >
            <Form 
                layout={'vertical'}
                initialValues={{
                    ...cors
                }}
            >
                <Item label={Locale('name')}>
                    <Input placeholder={Locale('name of CORS')} onChange={(e) => handleValue(e, 'name')} value={cors?.name} />
                </Item>
            </Form>
            <Divider />
            <Tabs
                defaultActiveKey="2"
                items={[Locale('production'), Locale('homolog'), 'Sandbox', Locale('dev')].map((tabName, i) => {
                    let env = ''

                    switch (tabName) {
                        case 'Desenvolvimento':
                            env = 'dev'
                            break
                        case 'Sandbox':
                            env = 'sdb'
                            break
                        case 'Homologação':
                            env = 'hml'
                            break
                        case 'Produção':
                            env = 'prd'
                            break
                    }

                    return {
                        label: (
                        <span>
                            {tabName}
                        </span>
                        ),
                        key: i + 1,
                        children: mountTab(env),
                    }
                })}
            />
        </Modal>
    )
} 