import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Space, 
    Button,
    Row,
    Col
} from 'antd'
import useSWR, { useSWRConfig } from 'swr'
import {
    PERMISSIONS
} from '../../environment'
import { 
    Table,
    UserPolicyModal,
    DestroyUserPolicyModal
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { UserPolicies } from '../../modules'
import { 
    DeleteFilled,
    EditFilled
} from '@ant-design/icons'
import { Locale } from '../../helpers/locale'

const { Link } = Typography

export default function UsersPoliciesPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    tableColumns = [
        {
            title: Locale('name'),
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: Locale('actions'),
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                        <Link onClick={() => handleEditModal(row)} href='#'>
                            <EditFilled  style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                        <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                            <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Link>
                    )}
                </Space>
            ),
        },
    ]

    const { data: usersPoliciesData, isLoading: usersPoliciesIsLoading } = cache.get('@usersPolicies') ?? { data: [] }

    const mutating = async () => {  
        await mutate('@usersPolicies', () => UserPolicies.getUsersPolicies({}))

        const newCache = cache.get('@usersPolicies')?.data ?? []

        setTableData(newCache?.map((conn, i) => {
            return {
                key: String(i),
                ...conn
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleEditModal = (userPolicy) => {
        setEditData({ ...userPolicy })
    }

    const handleDestroyModal = (userPolicy) => {
        setDestroyData({ ...userPolicy })
    }

    const handleCreateModal = () => {
        setCreateData({ closer: 1 })
    }

    const handleEditModalOpen = () => {
        return <UserPolicyModal 
            title={`${Locale('editing user policy: ')}${editData?.name}`} 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            userPolicyData={editData} 
            mutating={mutating}
        />
    }

    const handleCreateModalOpen = () => {
        return <UserPolicyModal 
            title={Locale('new user policy')} 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            userPolicyData={{}} 
            mutating={mutating}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyUserPolicyModal 
            title={Locale('delete user policy')} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        setTableData(usersPoliciesData?.map((policy, i) => {
            return {
                key: String(i),
                ...policy
            }
        }) || [])
        setIsLoading(false)
    }, [isLoading, usersPoliciesIsLoading])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='8' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        { Locale('users policies') }
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
}