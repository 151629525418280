import React, { useEffect, useState } from 'react'
import { 
    Typography, 
    Button,
    Row,
    Col,
    Tooltip,
    Form,
    Input,
    Collapse,
    Divider
} from 'antd'
import { 
    InfoCircleOutlined,
} from '@ant-design/icons'
import { useSWRConfig } from 'swr'
import {
    PERMISSIONS
} from '../../environment'
import { 
    Notification
} from '../../components'
import {
    PrincipalContainer
} from '../../containers'
import { Replicator } from '../../modules'
import { Locale, setLocale } from '../../helpers/locale'

const { Item } = Form

export default function ReplicatorPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const [editData, setEditData] = useState({}),
        [tabs, setTabs] = useState([])

    const { data: replicatorData } = cache.get('@replicator') || { data: [] }

    const handleSave = async () => {
        await Replicator.updateClientReplicator({ replicatorData: editData, id: editData?._id })

        await mutating()

        return Notification({
            title: Locale('configurations of magic deploy updated successfully!')
        })
    }

    const mutating = async () => {
        await mutate('@replicator', () => Replicator.getClientReplicator({}))

        const newCache = cache.get('@replicator')?.data ?? []

        if(newCache?.length) {
            const replicator = newCache?.[0] || {}

            setEditData({ ...replicator })
        }
    }

    const handleValue = (e, key, type = null, isDelete = false) => {
        const splitedKeys = key.split('.'),
            hasMoreThanOneKey = splitedKeys.length > 1

        const value = e?.target?.value

        if(hasMoreThanOneKey) {
            let tmpValue = null

            splitedKeys.forEach((key, i) => {
                if(!i) {
                    tmpValue = editData[key]
                } else if (i !== (splitedKeys.length - 1)) {
                    tmpValue = tmpValue[key]
                }
            })

            if(type && !isDelete) {
                const r = new RegExp(`array.string.\*`, 'g')

                if(type.match(r)?.length) {
                    const index = parseInt(type.split('.')[2])
                    let hasIndex = typeof tmpValue[splitedKeys[splitedKeys.length - 1]][index] == 'string'

                    if(!hasIndex) tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                    else {
                        let newValue = hasIndex ? tmpValue[splitedKeys[splitedKeys.length - 1]][index] : ''
                        
                        newValue = !value ? '': value
    
                        tmpValue[splitedKeys[splitedKeys.length - 1]][index] = newValue
                    }
                } else if(!value || isDelete) {
                    tmpValue[splitedKeys[splitedKeys.length - 1]] = []
                } else {
                    tmpValue[splitedKeys[splitedKeys.length - 1]].push(value)
                }
            } else {
                tmpValue[splitedKeys[splitedKeys.length - 1]] = value
            }
        } else {
            editData[key] = value
        }

        setEditData({ ...editData })
    }

    const mountCollapses = () => {
        const panels = [
            {
                title: Locale('configurations'),
                children: () => {
                    return (
                        <>
                            <Form 
                                layout={'vertical'}
                            >
                                <Typography.Title level={4} style={{ textAlign: 'left' }}>PRD</Typography.Title>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={12}>
                                        <Item label="URL">
                                           <Input placeholder="Host" onChange={(e) => handleValue(e, `3.host`)} value={editData[3]?.host}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Porta">
                                            <Input placeholder="Port" onChange={(e) => handleValue(e, `3.port`)} value={editData[3]?.port}  />
                                        </Item>
                                    </Col>
                                </Row>
                                <Divider></Divider>
                                <Typography.Title level={4} style={{ textAlign: 'left' }}>SDB</Typography.Title>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={12}>
                                        <Item label="URL">
                                            <Input placeholder="Host" onChange={(e) => handleValue(e, `2.host`)} value={editData[2]?.host}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Porta">
                                            <Input placeholder="Port" onChange={(e) => handleValue(e, `2.port`)} value={editData[2]?.port}  />
                                        </Item>
                                    </Col>
                                </Row>
                                <Divider></Divider>
                                <Typography.Title level={4} style={{ textAlign: 'left' }}>HML</Typography.Title>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={12}>
                                        <Item label="URL">
                                            <Input placeholder="Host" onChange={(e) => handleValue(e, `1.host`)} value={editData[1]?.host}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Porta">
                                            <Input placeholder="Port" onChange={(e) => handleValue(e, `1.port`)} value={editData[1]?.port}  />
                                        </Item>
                                    </Col>
                                </Row>
                                <Divider></Divider>
                                <Typography.Title level={4} style={{ textAlign: 'left' }}>DEV</Typography.Title>
                                <Row 
                                    gutter={{
                                        xs: 8,
                                        sm: 16,
                                        md: 24,
                                        lg: 32,
                                    }}
                                >
                                    <Col className="gutter-row" span={12}>
                                        <Item label="URL">
                                            <Input placeholder="Host" onChange={(e) => handleValue(e, `0.host`)} value={editData[0]?.host}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Item label="Porta">
                                            <Input placeholder="Port" onChange={(e) => handleValue(e, `0.port`)} value={editData[0]?.port}  />
                                        </Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        {/* {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && ( */}
                                            <Button style={{ float: 'right' }} type="primary" onClick={handleSave}>{Locale('save')}</Button>
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )
                }
            }
        ]

        return panels.map((panel, i) => <Collapse.Panel key={++i} header={
            <>
                <Typography.Title level={5} style={{ textAlign: 'left', marginTop: 3 }}>
                    {panel.title}
                </Typography.Title>
            </>
        }>
            {panel.children()}
        </Collapse.Panel>)
    }

    useEffect(() => {
        const replicator = replicatorData || {
            '3': {
                'host': '',
                'port': ''
            },
            '2': {
                'host': '',
                'port': ''
            },
            '1': {
                'host': '',
                'port': ''
            },
            '0': {
                'host': '',
                'port': ''
            }
        }

        setEditData({ ...replicator })
    }, [replicatorData])

    return (
        <>
            <PrincipalContainer menuItemSelected='21' setCurrentPage={setCurrentPage} content={
                <div>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        {Locale('magic deploy')}
                        <Tooltip placement="topLeft" title={Locale('define url and port by environment of magic deploy')}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                    </Typography.Title>
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_LIST) && (
                        <Row>
                            <Col span={24}>
                                <Collapse
                                    accordion={true}
                                    bordered={false}
                                    activeKey={['1']}
                                >
                                    {mountCollapses()}
                                </Collapse> 
                            </Col>
                        </Row>
                    )}
                </div>
            } />
        </>
    )
}