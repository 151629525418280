import API from '../../services/api'
import { URLS } from '../../environment.js'
import { 
    verifyPermission, 
    permissionsStatus 
} from '../../helpers/permissions'

const getClientRollbacks = async ({ limit = 20, offset = 0, id = null }) => {
    // if(!verifyPermission('rollback', permissionsStatus.list)) return []

    const response  = await API.get(URLS.rollback.list(limit, offset, id))

    return response?.data
}

const updateClientRollback = async ({ rollbackData, id }) => {
    if(!verifyPermission('rollback', permissionsStatus.update)) return {}

    const { data: response, isError } = await API.put(URLS.rollback.update(id), rollbackData)

    return response?.data || isError
}

const Rollback = {
    getClientRollbacks,
    updateClientRollback
}

export default Rollback