import React, { useEffect, useState } from "react"
import { 
    Modal,
    Popconfirm,
    Col,
    Row,
    Button,
    Typography
} from 'antd'
import { Notification } from "../.."
import { 
    PullRequest
} from "../../../modules"
import { DiffEditor } from '@monaco-editor/react'
import DateFormat from '../../../helpers/date-format'
import { Locale } from '../../../helpers/locale'

export default function PullRequestModal ({ open = false, setEditData = () => {}, prId = null, handleOpen = () => {}, pullRequestData = {}, mutating = () => {} }) {
    const [pullRequest, setPullRequest] = useState({}),
    [loading, setLoading] = useState(false),
    [usernamePR, setUsernamePR] = useState('')

    const handleApprove = async () => {
        setLoading(true)

        await PullRequest.approvePullRequest({ id: pullRequest._id })
        
        setLoading(false)
        handleOpen(false)
        handleNotificationApproved()
        await mutating()
    }

    const handleDeaprove = async () => {
        setLoading(true)
        
        await PullRequest.deaprovePullRequest({ id: pullRequest._id })

        setLoading(false)
        handleOpen(false)
        handleNotificationDeaproved()
        await mutating()
    }

    const handleNotificationApproved = () => {
        Notification({ 
            description: `Pull request #${pullRequest._id} ${Locale('approved with success')}`,
            title: 'Pull request'
        })
    }

    const handleNotificationDeaproved = () => {
        Notification({ 
            description: `Pull request #${pullRequest._id} desaprovada com sucesso`,
            title: 'Pull request'
        })
    }

    useEffect(() => {
        setPullRequest({ ...pullRequestData })
        
        if(pullRequestData?.logs?.length) {
            const prLogs = pullRequestData?.logs[pullRequestData?.logs?.length - 1],
                username = prLogs.split(' ')[0]
            
            setUsernamePR(username)
        }
    }, [prId])

    return (
        <Modal
            title={`#${pullRequest._id} ${pullRequest?.description?.length ? `- ${pullRequest?.description}` : ''}`}
            centered
            open={open}
            onCancel={() => { 
                setEditData({})
                setLoading(false)
                handleOpen(false)
            }}
            // onOk={async () => await handleApprove()}
            width={1000}
            okText="Aprovar"
            cancelText="Não aprovar"
            cancelButtonProps={{ 
                type: 'text', 
                style: { 
                    backgroundColor: 'red', 
                    color: '#fff' 
                },
                onClick: async () => {
                    await handleDeaprove()
                } 
            }}
            footer={<div style={{ display: 'inline-block' }}>
                <Popconfirm
                    title={`${Locale('deaprove')} Pull Request`}
                    description={Locale('are you sure you want to deaprove the merge of changes?')}
                    onConfirm={async () => await handleDeaprove()}
                    okText={Locale('yes')}
                    cancelText={Locale('no')}
                >
                    <Button style={{ backgroundColor: 'red', color: '#fff' }} type="primary">Desaprovar</Button>
                </Popconfirm>
                <Popconfirm
                    title={`${Locale('approve')} Pull Request`}
                    description={Locale('are you sure you want to approve the merge of changes?')}
                    onConfirm={async () => await handleApprove()}
                    okText={Locale('yes')}
                    cancelText={Locale('no')}
                >
                    <Button type="primary">{Locale('approve')}</Button>
                </Popconfirm>
                
            </div>}
        >
            <h3>{pullRequest?.description || ''}</h3>
            <h5>{`${usernamePR} - ${DateFormat(pullRequest.created_at)}`}</h5>
            <Row 
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
            >
                <Col span={24}>
                    <DiffEditor
                        theme='vs-dark'
                        height="70vh"
                        language="javascript"
                        original={pullRequest?.old_code || ''}
                        modified={pullRequest?.code || ''}
                    />
                    {/* <Item label="Nome">
                        <Input placeholder="Nome do app" onChange={(e) => handleValue(e, 'name')} value={app?.name}  />
                    </Item> */}
                </Col>
                <Col span={24}>
                    <Typography.Title style={{ marginTop: 20 }} level={3}>Logs</Typography.Title>
                    <pre style={{ 
                        padding: 10, 
                        border: '1px solid #ccc',
                        borderRadius: 5
                    }}>
                        {pullRequest?.logs?.map(log => {
                            return (<Typography.Paragraph>
                                {log}
                            </Typography.Paragraph>)
                        })}
                    </pre>
                    {/* <Item label="Nome">
                        <Input placeholder="Nome do app" onChange={(e) => handleValue(e, 'name')} value={app?.name}  />
                    </Item> */}
                </Col>
            </Row>
        </Modal>
    )
} 