import React, { useState } from "react"
import { 
    Typography,
    Form,
    Input,
    Col,
    Button
} from 'antd'
import { Setup, User } from '../../modules'
import { Notification } from "../../components"
import { 
    AlertOutlined
} from '@ant-design/icons'
import { Locale } from "../../helpers/locale"

const { Item } = Form

export default function PageThreeSetup ({ state = {}, handleState = () => {}, handlePage = () => {} }) {
    const [componentState, setComponentState] = useState({
        username: '',
        user_email: '',
        password: '',
        private_key: window.crypto.randomUUID()
    })
    
    const handleFinalize = async () => {
        let isOK = true,
            errorsBag = []

        Object.keys(componentState).forEach(key => {
            const value = componentState[key]

            switch (key) {
                case 'username':
                    isOK = (value?.length >= 3 && value?.length <= 12)
                    if(!isOK) errorsBag.push(<p>{Locale('the name is required and have a length between 3 and 12')}</p>)
                    break
                case 'user_email':
                    if(value.match(/@/)) {
                        if(value.match(/.com/)) {
                            isOK = true
                        }
                    } else {
                        isOK = false
                    }
                    if(!isOK) errorsBag.push(<p>{Locale('the email is required and need be valid')}</p>)
                    break
                case 'password':
                    isOK = value?.length >= 8
                    if(!isOK) errorsBag.push(<p>{Locale('the password is required and have a length between 8 or more characters')}</p>)
                    break
            }
        })

        if(!isOK) return Notification({
            title: Locale('finalize'),
            type: 'warning',
            description: errorsBag
        })

        state = {
            ...state,
            ...componentState
        }

        const data = await User.verifyEmail({ email: componentState.user_email })

        if(!data) return Notification({
            title: Locale('finalize'),
            type: 'warning',
            description: Locale('this email is not valid or we have in our db')
        })

        const response = await Setup.create({ state })

        state.token = response.token

        handleState({ ...state })
        
        handlePage(4)
    }

    return (
        <>
            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                {Locale('type the infos below to create adm user')}
            </Typography.Title>
            <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Form layout="vertical" style={{ width: '100%' }}>
                    <Item label={Locale('name')}>
                        <Input
                            value={componentState.username}
                            onChange={(e) => {
                                componentState.username = e.target.value

                                setComponentState({ ...componentState })
                            }} 
                        />
                    </Item>
                    <Item label={'Email'}>
                        <Input 
                            value={componentState.user_email}
                            onChange={(e) => {
                                componentState.user_email = e.target.value

                                setComponentState({ ...componentState })
                            }} 
                        />
                    </Item>
                    <Item label={Locale('password')}>
                        <Input.Password
                            value={componentState.password}
                            onChange={(e) => {
                                componentState.password = e.target.value

                                setComponentState({ ...componentState })
                            }} 
                        />
                    </Item>
                </Form>
            </Col>
            <div 
                style={{
                    float: 'right'
                }}
            >
                <Button 
                    style={{ 
                        float: 'right',
                        marginRight: 17
                    }} 
                    onClick={async () => await handleFinalize()}
                    shape="round" size={'large'}
                >
                    {Locale('finalize')}
                </Button>
            </div>
        </>
    )
} 