import React, { useEffect, useState } from 'react'
import { 
    Col,
    Row,
    Typography,
    Form,
    Button,
    Tag,
    Space,
    Tooltip
} from 'antd'
import { 
    Table,
    DestroyTasksModal,
    TasksModal
} from '../../components'
import {
    DeleteFilled,
    EditFilled,
    InfoCircleOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined
} 
from '@ant-design/icons'
import {
    PrincipalContainer
} from '../../containers'
import { Tasks } from '../../modules'
import {
    PERMISSIONS
} from '../../environment'
import { Locale } from '../../helpers/locale'
import { useSWRConfig } from 'swr'

const { Text, Link } = Typography,
    { Item } = Form

export default function TasksPage ({ setCurrentPage, userPermissions }) {
    const { cache, mutate } = useSWRConfig()

    const { data: tasksData, isLoading: tasksIsLoading } = cache.get('@tasks') ?? { data: [] }

    const [tableData, setTableData] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [editData, setEditData] = useState({}),
    [createData, setCreateData] = useState({}),
    [destroyData, setDestroyData] = useState({}),
    [openEditModal, setOpenEditModal] = useState(false),
    [openCreateModal, setOpenCreateModal] = useState(false),
    [openDestroyModal, setOpenDestroyModal] = useState(false),
    tableColumns = [
        {
            title: Locale('name'),
            dataIndex: 'name',
            key: 'name',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: Locale('status'),
            dataIndex: 'status',
            key: 'status',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                renderTagTable(row.status)
            )
        },
        {
            title: Locale('expression'),
            dataIndex: 'crontab_expression',
            key: 'crontab_expression',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
        },
        {
            title: Locale('actions'),
            dataIndex: 'actions',
            key: '_id',
            responsive: [ 'xs', 'sm', 'md', 'lg' ],
            render: (_, row) => (
                <Space size="middle">
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                        <Link onClick={() => handleEditModal(row)} href='#'>
                            <EditFilled  style={{ fontSize: '16px' }} />
                        </Link>
                    )}
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                        <Link href='#' type='danger' onClick={() => handleDestroyModal(row)}>
                            <DeleteFilled style={{ fontSize: '16px', color: '#d9534f' }} />
                        </Link>
                    )}
                    {userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                        <Link href='#' type='danger' onClick={async () => row.status == 1 ? await handleStopTask(row) : await handleActiveTask(row)}>
                            {row.status == 1 ? 
                                <PauseCircleOutlined style={{ fontSize: '16px', color: '#faad14' }} />
                            :
                            <PlayCircleOutlined style={{ fontSize: '16px', color: '#52c41a' }} />}
                        </Link>
                    )}
                </Space>
            ),
        },
    ]

    const handleEditModal = (task) => {
        setEditData({ ...task })
    }
    
    const handleActiveTask = async (task) => {
        const id = task._id

        task.status = 1

        await Tasks.updateClientTask({ task, id })

        await mutating()
    }

    const handleStopTask = async (task) => {
        const id = task._id

        task.status = 2

        await Tasks.updateClientTask({ task, id })

        await mutating()
    }

    const handleDestroyModal = (task) => {
        setDestroyData({ ...task })
    }

    const handleOpenCreateModal = () => {
        setCreateData({ closer: 1 })
    }

    const renderTagTable = (status) => {
        let tag = <></>

        switch (status) {
            case 2:
                tag = <Tag color="warning">
                    {Locale('stoped')}
                </Tag>
                break
            case 1:
                tag = <Tag color="success">
                    {`online`}
                </Tag>
                break
        }

        return tag
    }

    const mutating = async () => {
        await mutate('@tasks', Tasks.getClientTasks({}))

        const newCache = cache.get('@tasks')?.data ?? []

        setTableData(newCache?.map((task, i) => {
            return {
                key: String(i),
                ...task
            }
        }) || [])

        setEditData({})
        setDestroyData({})
        setCreateData({})

        setIsLoading(false)
    }

    const handleEditModalOpen = () => {
        return <TasksModal 
            title={`${Locale('editing')} task: ${editData?.name}`} 
            open={openEditModal} 
            handleOpen={setOpenEditModal} 
            editTask={editData} 
            mutating={mutating}
        />
    }

    const handleCreateModalOpen = () => {
        return <TasksModal 
            title={Locale('creating new task')} 
            open={openCreateModal} 
            handleOpen={setOpenCreateModal} 
            editTask={createData} 
            mutating={mutating}
        />
    }

    const handleDestroyModalOpen = () => {
        return <DestroyTasksModal 
            title={`${Locale('exclude')} task`} 
            open={openDestroyModal} 
            handleOpen={setOpenDestroyModal} 
            id={destroyData._id}
            name={destroyData.name} 
            mutating={mutating}
        />
    }

    useEffect(() => {
        if(Object.keys(createData).length) setOpenCreateModal(true)
        else setOpenCreateModal(false)
    }, [createData])

    useEffect(() => {
        if(Object.keys(editData).length) {
            setCreateData({})
            setOpenEditModal(true)
        }
        else setOpenEditModal(false)
    }, [editData])

    useEffect(() => {
        if(Object.keys(destroyData).length) setOpenDestroyModal(true)
        else setOpenDestroyModal(false)
    }, [destroyData])

    useEffect(() => {
        setTableData(tasksData?.map((task, i) => {
            return {
                key: String(i),
                ...task
            }
        }) || [])

        setIsLoading(false)
    }, [isLoading, tasksIsLoading])

    return (
        <>
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_EDIT) && (
                    handleEditModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                    handleCreateModalOpen()
                )
            }
            {
                userPermissions?.includes(PERMISSIONS.PERMISSION_DELETE) && (
                    handleDestroyModalOpen()
                )
            }
            <PrincipalContainer menuItemSelected='22' setCurrentPage={setCurrentPage} content={
                <>
                    <Typography.Title level={2} style={{ textAlign: 'left' }}>
                        Tasks
                        <Tooltip placement="topLeft" title={Locale('define tasks to execute automatically')}>
                            <InfoCircleOutlined style={{ marginLeft: 10 }} /> 
                        </Tooltip>
                        {userPermissions?.includes(PERMISSIONS.PERMISSION_CREATE) && (
                            <Button style={{ float: 'right', top: 5 }} type="primary" shape="round" onClick={handleOpenCreateModal}>+</Button>
                        )}
                    </Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Table 
                                columns={tableColumns}
                                isLoading={isLoading}
                                tableData={tableData}
                            />
                        </Col>
                    </Row>
                </>
            } />
        </>
    )
    
}